<template>
  <v-app>
    <!--
      Caso não seja um celular um cartão vai ser adicionado onde teremos opções de:
        - Seleção de origem
        - Seleção de destino 
        - Traçar rota
    -->
    <v-card
      v-if="!$vuetify.breakpoint.mobile && this.$store.state.codeMall.box"
      elevation="10"
      width="300"
      class="traceRouteCard"
    >
      <!--
        Linha onde adiciono o logo do shopping
      -->
      <v-row v-if="this.$store.state.mallData.logoWebShow" fluid no-gutters>
        <v-container
          class="logoContainer"
          :style="{
            backgroundColor: $store.state.mallData.webHeaderBoxBackgroundColor,
          }"
        >
          <v-img
            width="100"
            height="50"
            contain
            class="ma-2"
            :src="this.awsUrl + this.$store.state.mallData.logoUrlWeb"
          ></v-img>
        </v-container>
      </v-row>
      <!--
        Linha onde coloco a selação de Origem de loja
      -->
      <v-row v-if="!hideOrigenBar" fluid no-gutters class="ma-4 mt-8">
        <v-autocomplete
          v-model="selectedOrigin"
          :items="listOfOrigenSpaces"
          item-text="space.name"
          item-value="objectName"
          return-object
          color="black"
          clearable
          outlined
          hide-details
          hide-no-data
          dense
          label="Aonde você esta?"
          :filter="returnHeadersAndFilter"
          @change="$store.commit('setOrigin', selectedOrigin)"
          @click:clear="$store.state.originSpace = null"
          :disabled="!this.$store.state.completeAllPath"
        ></v-autocomplete>
      </v-row>
      <!--
        Linha onde coloco a selação de Destino de loja
      -->
      <v-row v-if="!hideDestinationBar" fluid no-gutters class="ma-4">
        <v-autocomplete
          v-model="destination"
          :items="listOfDestinationSpaces"
          item-text="space.name"
          item-value="objectName"
          return-object
          color="black"
          clearable
          outlined
          hide-details
          hide-no-data
          dense
          label="Para onde quer ir?"
          :filter="returnHeadersAndFilter"
          @change="$store.commit('setDestination', destination)"
          @click:clear="$store.state.destinationSpace = null"
          :disabled="!this.$store.state.completeAllPath"
        ></v-autocomplete>
      </v-row>
      <!--
        Linha com botão para traçar a rota
      -->
      <v-row align="end" justify="end" class="ma-4 pb-2">
        <v-btn
          color="black"
          class="white--text"
          @click="chooseAccessRouteOption()"
          :disabled="
            !this.$store.state.completeAllPath ||
            this.$store.state.originSpace === null ||
            this.$store.state.destinationSpace === null
          "
          >Traçar Rota</v-btn
        >
      </v-row>
    </v-card>

    <!--
      Cartão onde vai aparecer o nome da loja + foto, por onde o mouse passou
    -->
    <v-card v-if="selectedSpaceName" id="spaceNameContainer">
      <v-col class="d-flex flex-column ma-0 pa-0">
        <span class="black--text font-weight-bold mr-2">{{
          selectedSpaceName.space.name
        }}</span>
        <span
          v-if="selectedSpaceName.space.floor !== null"
          class="mr-2"
          :style="{ color: $store.state.mallData.webButtonBackgroundColor }"
          >{{ "Piso: " + selectedSpaceName.space.floor }}</span
        >
      </v-col>
      <v-col
        v-if="selectedSpaceName.space.pictureUrl"
        cols="5"
        class="ma-0 pa-0"
      >
        <v-img
          :src="selectedSpaceName.space.pictureUrl"
          width="120"
          contain
        ></v-img>
      </v-col>
    </v-card>
    <!--
      Container onde coloco todos os botões de opções como:
        - Vizualizar todos elevadores
        - Vizualizar todas as escadas
        - Trocar os andares
    -->
    <v-container
      :fluid="!$vuetify.breakpoint.mobile"
      :class="
        !$vuetify.breakpoint.mobile
          ? 'actionsButtonsDesktop'
          : 'actionsButtonsMobile'
      "
    >
      <!--
          Card para as ações do mapa versão Desktop
      -->
      <v-card
        elevation="10"
        v-if="!$vuetify.breakpoint.mobile"
        :class="
          $store.state.toggleCameraHide
            ? 'hiddenCameraButton'
            : 'showCameraButton'
        "
      >
        <v-row class="ma-2">
          <v-col
            class="ma-0 pa-0 mr-4 d-flex flex-column justify-center align-center"
          >
            <v-btn
              :id="changeCamera ? 'selectedAction' : 'defaultAction'"
              :style="
                changeCamera
                  ? {
                      backgroundColor:
                        $store.state.mallData.webButtonBackgroundColor,
                    }
                  : {
                      backgroundColor: '#ffffff',
                    }
              "
              @click="
                $refs.MapComponent.changeCameraView(),
                  (changeCamera = !changeCamera)
              "
              ><v-icon
                x-large
                :color="
                  changeCamera ? 'white' : $store.state.mallData.webDestakColor
                "
                >{{ changeCamera ? "mdi-map-outline" : "mdi-run-fast" }}</v-icon
              ></v-btn
            >
            <span
              class="subtitle"
              :style="{
                color: $store.state.mallData.webDestakColor,
              }"
              >{{ !changeCamera ? "Câmera" : "Mapa" }}</span
            >
          </v-col>
          <v-col
            class="ma-0 pa-0 mr-4 d-flex flex-column justify-center align-center"
          >
            <v-btn
              :id="stopRoute ? 'selectedAction' : 'defaultAction'"
              :style="
                stopRoute
                  ? {
                      backgroundColor:
                        $store.state.mallData.webButtonBackgroundColor,
                    }
                  : {
                      backgroundColor: '#ffffff',
                    }
              "
              @click="
                $refs.MapComponent.pauseRoute(stopRoute),
                  (stopRoute = !stopRoute)
              "
              ><v-icon
                x-large
                :color="
                  stopRoute ? 'white' : $store.state.mallData.webDestakColor
                "
                >{{ stopRoute ? "mdi-play" : "mdi-pause" }}</v-icon
              ></v-btn
            >
            <span
              class="subtitle"
              :style="{
                color: $store.state.mallData.webDestakColor,
              }"
              >{{ stopRoute ? "Iniciar" : "Pausar" }}</span
            >
          </v-col>
          <v-col
            class="ma-0 pa-0 mr-4 d-flex flex-column justify-center align-center"
          >
            <v-btn id="defaultAction" @click="setSpeed()"
              ><v-icon x-large :color="$store.state.mallData.webDestakColor">{{
                setSpeedIcon()
              }}</v-icon
              ><span
                class="speedText"
                :style="{
                  color: $store.state.mallData.webDestakColor,
                }"
                >{{ this.$store.state.personSpeed / 3 }}x</span
              ></v-btn
            >
            <span
              class="subtitle"
              :style="{
                color: $store.state.mallData.webDestakColor,
              }"
              >Velocidade</span
            >
          </v-col>
          <v-col
            class="ma-0 pa-0 d-flex flex-column justify-center align-center"
          >
            <v-btn
              color="red"
              class="white--text"
              size="64"
              height="60"
              @click="
                $refs.MapComponent.resetAllRouteValues(), (stopRoute = true)
              "
            >
              X
            </v-btn>
            <span
              class="subtitle"
              :style="{
                color: $store.state.mallData.webDestakColor,
              }"
              >Cancelar</span
            >
          </v-col>
        </v-row>
      </v-card>
      <!--
        Coluna com todos os botões de ações como elevadores, escadas e andares para Desktop
      -->
      <v-col v-if="!$vuetify.breakpoint.mobile" cols="1" class="floorSelection">
        <!--
          Cartão com botão para mostrar ou não os elevadores
        -->
        <v-row :class="rightButtons.length > 4 ? 'spaceCards' : 'normalCards'">
          <v-col
            cols="4"
            class="d-flex flex-row justify-end align-center ma-0 pa-0"
            ><span
              class="subtitle"
              :style="{
                color: $store.state.mallData.webDestakColor,
              }"
              >Elevadores</span
            ></v-col
          >
          <v-col cols="7" class="ma-0 pa-0">
            <v-card
              width="133"
              height="70"
              elevation="5"
              class="ma-2"
              :id="showAllElevators ? 'selectedAction' : 'defaultAction'"
              :style="
                showAllElevators
                  ? {
                      backgroundColor:
                        $store.state.mallData.webButtonBackgroundColor,
                    }
                  : {
                      backgroundColor: '#ffffff',
                    }
              "
              @click="
                (showAllElevators = !showAllElevators),
                  $refs.MapComponent.showElevators()
              "
              :disabled="!$store.state.completeAllPath"
            >
              <SVGLoader
                selected-icon="elevator"
                :class="
                  showAllElevators ? 'activeSVGDesktop' : 'defaultSVGDesktop'
                "
                :style="
                  showAllElevators
                    ? {
                        color: '#ffffff',
                      }
                    : {
                        color: $store.state.mallData.webDestakColor,
                      }
                "
              ></SVGLoader>
            </v-card>
          </v-col>
        </v-row>
        <!--
          Cartão com botão para mostrar ou não as escadas
        -->
        <v-row :class="rightButtons.length > 4 ? 'spaceCards' : 'normalCards'">
          <v-col
            cols="4"
            class="d-flex flex-row justify-end align-center ma-0 pa-0"
            ><span
              class="subtitle"
              :style="{
                color: $store.state.mallData.webDestakColor,
              }"
              >Escadas</span
            ></v-col
          >
          <v-col cols="7" class="ma-0 pa-0">
            <v-card
              width="133"
              height="70"
              elevation="5"
              class="ma-2"
              :id="showAllStairs ? 'selectedAction' : 'defaultAction'"
              :style="
                showAllStairs
                  ? {
                      backgroundColor:
                        $store.state.mallData.webButtonBackgroundColor,
                    }
                  : {
                      backgroundColor: '#ffffff',
                    }
              "
              @click="
                (showAllStairs = !showAllStairs),
                  $refs.MapComponent.showStairs()
              "
              :disabled="!$store.state.completeAllPath"
            >
              <SVGLoader
                selected-icon="escalator"
                :class="
                  showAllStairs ? 'activeSVGDesktop' : 'defaultSVGDesktop'
                "
                :style="
                  showAllStairs
                    ? {
                        color: '#ffffff',
                      }
                    : {
                        color: $store.state.mallData.webDestakColor,
                      }
                "
              ></SVGLoader>
            </v-card>
          </v-col>
        </v-row>
        <!--
          Grupo com todos os botão de todos os possiveis andares
        -->
        <v-row class="floorCards">
          <v-col cols="8">
            <v-item-group v-model="selectedButton" mandatory>
              <v-col
                v-for="buttons in rightButtons"
                :key="buttons.name"
                class="pa-2"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    width="150"
                    height="70"
                    elevation="5"
                    :id="active ? 'selectedAction' : 'defaultAction'"
                    @click="toggle"
                    :disabled="!$store.state.completeAllPath"
                    class="d-flex align-center justify-center"
                    :style="
                      active
                        ? {
                            backgroundColor:
                              $store.state.mallData.webButtonBackgroundColor,
                          }
                        : {
                            backgroundColor: '#ffffff',
                          }
                    "
                  >
                    <h2
                      :class="
                        active
                          ? 'floorTextActive text-truncate'
                          : 'floorTextDeActive text-truncate'
                      "
                      :style="
                        active
                          ? {
                              color: '#ffffff',
                            }
                          : {
                              color: $store.state.mallData.webDestakColor,
                            }
                      "
                    >
                      {{ buttons.media ? null : buttons.name }}
                    </h2>
                  </v-card>
                </v-item>
              </v-col>
            </v-item-group>
          </v-col>
        </v-row>
      </v-col>
      <!--
        Linha com botão para cancelar rota para mobile
      -->
      <v-slide-y-transition>
        <v-row v-if="$store.state.inRoute && $vuetify.breakpoint.mobile">
          <v-btn
            dense
            small
            block
            color="red"
            class="white--text"
            @click="
              $refs.MapComponent.resetAllRouteValues(), (stopRoute = true)
            "
            >X</v-btn
          >
        </v-row>
      </v-slide-y-transition>
      <!--
        Linha com botão de filtro e outras ações
      -->
      <v-row
        class="mt-2 mr-4"
        justify="end"
        align="center"
        v-if="$vuetify.breakpoint.mobile"
      >
        <v-scroll-x-reverse-transition>
          <!--
              Card com botão para alternar a visão
          -->
          <v-card
            v-if="
              !$store.state.toggleCameraHide &&
              $store.state.mallData.webMapShowPersonage
            "
            :id="changeCamera ? 'selectedAction' : 'defaultAction'"
            :style="
              changeCamera
                ? {
                    backgroundColor:
                      $store.state.mallData.webButtonBackgroundColor,
                  }
                : {
                    backgroundColor: '#ffffff',
                  }
            "
            width="70"
            height="50"
            elevation="5"
            class="ma-2 d-flex flex-column justify-center align-center"
            @click="
              $refs.MapComponent.changeCameraView(),
                (changeCamera = !changeCamera)
            "
          >
            <v-icon
              class="mt-2"
              medium
              :color="
                changeCamera ? 'white' : $store.state.mallData.webDestakColor
              "
              >{{ changeCamera ? "mdi-map-outline" : "mdi-run-fast" }}</v-icon
            >
            <span
              :class="!changeCamera ? 'subtitle' : 'subtitleActive'"
              :style="
                !changeCamera
                  ? {
                      color: $store.state.mallData.webDestakColor,
                    }
                  : {
                      color: '#ffffff',
                    }
              "
              >{{ !changeCamera ? "Câmera" : "Mapa" }}</span
            >
          </v-card>
        </v-scroll-x-reverse-transition>
        <v-scroll-x-reverse-transition>
          <!--
              Card com botão para pausar rota
          -->
          <v-card
            v-if="
              !$store.state.toggleCameraHide &&
              $store.state.mallData.webMapShowPersonage
            "
            :id="stopRoute ? 'selectedAction' : 'defaultAction'"
            :style="
              stopRoute
                ? {
                    backgroundColor:
                      $store.state.mallData.webButtonBackgroundColor,
                  }
                : {
                    backgroundColor: '#ffffff',
                  }
            "
            width="70"
            height="50"
            elevation="5"
            class="ma-2 flex-column justify-center align-center"
            @click="
              $refs.MapComponent.pauseRoute(stopRoute), (stopRoute = !stopRoute)
            "
          >
            <v-icon
              class="mt-2"
              medium
              :color="
                stopRoute ? 'white' : $store.state.mallData.webDestakColor
              "
              >{{ stopRoute ? "mdi-play" : "mdi-pause" }}</v-icon
            >
            <span
              :class="stopRoute ? 'subtitleActive' : 'subtitle'"
              :style="
                stopRoute
                  ? {
                      color: '#ffffff',
                    }
                  : {
                      color: $store.state.mallData.webDestakColor,
                    }
              "
              >{{ stopRoute ? "Iniciar" : "Pausar" }}</span
            >
          </v-card>
        </v-scroll-x-reverse-transition>
        <v-scroll-x-reverse-transition>
          <!--
              Card com botão para ajustar velocidade
          -->
          <v-card
            v-if="
              !$store.state.toggleCameraHide &&
              $store.state.mallData.webMapShowPersonage
            "
            id="defaultAction"
            width="70"
            height="50"
            elevation="5"
            class="ma-2 flex-column justify-center align-center"
            @click="setSpeed()"
          >
            <v-icon
              class="mt-2"
              medium
              :color="$store.state.mallData.webDestakColor"
              >{{ setSpeedIcon() }}</v-icon
            ><span
              class="speedTextMobile"
              :style="{
                color: $store.state.mallData.webDestakColor,
              }"
              >{{ this.$store.state.personSpeed / 3 }}x</span
            >
            <span
              class="subtitle"
              :style="{
                color: $store.state.mallData.webDestakColor,
              }"
              >Velocidade</span
            >
          </v-card>
        </v-scroll-x-reverse-transition>
        <!--
            Card com o botão de filtros
        -->
        <v-menu
          offset-y
          transition="slide-y-transition"
          min-width="80"
          tile
          :close-on-content-click="false"
          content-class="menuContent"
        >
          <template v-slot:activator="{ on, attrs, value }">
            <v-card
              width="70"
              height="50"
              v-bind="attrs"
              v-on="on"
              elevation="5"
              class="d-flex flex-column justify-center align-center"
              :color="!value ? 'white' : 'black'"
              ><v-icon
                class="mt-2"
                :color="!value ? $store.state.mallData.webDestakColor : 'white'"
                >{{ !value ? "mdi-filter-outline" : "mdi-close" }}</v-icon
              >
              <span
                :class="!value ? 'subtitle' : 'subtitleActive'"
                :style="
                  !value
                    ? {
                        color: $store.state.mallData.webDestakColor,
                      }
                    : {
                        color: '#ffffff',
                      }
                "
                >{{ !value ? "Filtros" : "Fechar" }}</span
              >
            </v-card>
          </template>
          <!--
              Linha com o botão dos elevadores
            -->
          <v-card
            width="70"
            height="50"
            elevation="5"
            class="mb-2 d-flex flex-column justify-center align-center"
            :id="showAllElevators ? 'selectedAction' : 'defaultAction'"
            :style="
              showAllElevators
                ? {
                    backgroundColor:
                      $store.state.mallData.webButtonBackgroundColor,
                  }
                : {
                    backgroundColor: '#ffffff',
                  }
            "
            @click="
              (showAllElevators = !showAllElevators),
                $refs.MapComponent.showElevators()
            "
            :disabled="!$store.state.completeAllPath"
          >
            <SVGLoader
              selected-icon="elevator"
              :class="showAllElevators ? 'activeSVGMobile' : 'defaultSVGMobile'"
              :style="
                showAllElevators
                  ? {
                      color: '#ffffff',
                    }
                  : {
                      color: $store.state.mallData.webDestakColor,
                    }
              "
            ></SVGLoader>
            <span
              :class="!showAllElevators ? 'subtitle' : 'subtitleActive'"
              :style="
                showAllElevators
                  ? {
                      color: '#ffffff',
                    }
                  : {
                      color: $store.state.mallData.webDestakColor,
                    }
              "
              >Elevadores</span
            >
          </v-card>
          <!--
              Linha com o botão das escadas
            -->
          <v-card
            width="70"
            height="50"
            elevation="5"
            class="mb-2 d-flex flex-column justify-center align-center"
            :id="showAllStairs ? 'selectedAction' : 'defaultAction'"
            :style="
              showAllStairs
                ? {
                    backgroundColor:
                      $store.state.mallData.webButtonBackgroundColor,
                  }
                : {
                    backgroundColor: '#ffffff',
                  }
            "
            @click="
              (showAllStairs = !showAllStairs), $refs.MapComponent.showStairs()
            "
            :disabled="!$store.state.completeAllPath"
          >
            <SVGLoader
              selected-icon="escalator"
              :class="showAllStairs ? 'activeSVGMobile' : 'defaultSVGMobile'"
              :style="
                showAllStairs
                  ? {
                      color: '#ffffff',
                    }
                  : {
                      color: $store.state.mallData.webDestakColor,
                    }
              "
            ></SVGLoader>
            <span
              :class="!showAllStairs ? 'subtitle' : 'subtitleActive'"
              :style="
                showAllStairs
                  ? {
                      color: '#ffffff',
                    }
                  : {
                      color: $store.state.mallData.webDestakColor,
                    }
              "
              >Escadas</span
            >
          </v-card>
        </v-menu>
      </v-row>
    </v-container>
    <!--
      Barra de progresso de download do mapa
    -->
    <v-col
      v-if="this.$store.state.percentLoaded > 1"
      cols="4"
      align="center"
      justify="center"
      class="progressBar"
    >
      <v-progress-linear
        :value="this.$store.state.percentLoaded"
        striped
        height="10"
        color="green"
      ></v-progress-linear>
    </v-col>
    <THREEJS
      v-if="this.$store.state.mallData !== null"
      ref="MapComponent"
      @applyDestinationSpaceName="changeDestination"
      @applyOriginSpaceName="changeOrigin"
      @updateSpaceName="showSpaceName"
      @changeFloor="toggleFloor"
      @waitDialog="continuePath = true"
      @changeCameraButton="toggleCameraButton"
      @saveNextFloor="saveNextFloorName"
      @routeFinish="finishRouteDialog = true"
    />
    <!--
      Cartão com botão para aparecer seletor de origem e destino que vai aparecer somente quando for um celular
    -->
    <v-card
      v-if="
        $vuetify.breakpoint.mobile &&
        this.$store.state.codeMall.box &&
        this.$store.state.completeAllPath
      "
      class="mobileCard"
      min-height="45"
    >
      <v-row> <div id="mid" class="autocompletePosition"></div></v-row>
      <v-col class="ma-0 mt-1 pa-0 d-flex flex-column align-end justify-center">
        <v-container
          v-if="this.$store.state.mallData.logoWebShow"
          class="logoContainer"
          :style="{
            backgroundColor: $store.state.mallData.webHeaderBoxBackgroundColor,
          }"
        >
          <v-img
            width="100"
            height="50"
            contain
            class="ma-1"
            :src="this.awsUrl + this.$store.state.mallData.logoUrlWeb"
            @click="displayRoute = false"
          ></v-img>
          <div id="mid" style="position: absolute; bottom: 200%"></div>
          <v-icon
            v-if="displayRoute && this.$store.state.mallData.logoWebShow"
            large
            color="#ffffff"
            @click="displayRoute = false"
            >mdi-chevron-down</v-icon
          >
        </v-container>
        <v-row
          v-if="!this.$store.state.mallData.logoWebShow && displayRoute"
          class="mt-5"
        >
        </v-row>
        <v-icon
          v-if="displayRoute && !this.$store.state.mallData.logoWebShow"
          large
          color="#000000"
          @click="displayRoute = false"
          class="mt-6 mr-4"
          >mdi-chevron-down</v-icon
        >
        <v-btn
          v-if="!displayRoute"
          color="black"
          class="white--text ma-2"
          small
          @click="displayRoute = true"
          :disabled="!this.$store.state.completeAllPath"
          >Traçar Rota</v-btn
        >
      </v-col>
      <!--
        Linha onde coloco a selação de Origem de loja
      -->
      <v-row v-if="displayRoute && !hideOrigenBar" class="ma-4">
        <v-autocomplete
          v-model="selectedOrigin"
          :items="listOfOrigenSpaces"
          item-text="space.name"
          item-value="objectName"
          return-object
          color="black"
          clearable
          outlined
          hide-details
          hide-no-data
          dense
          open-on-clear
          label="Aonde você esta?"
          :filter="returnHeadersAndFilter"
          @change="$store.commit('setOrigin', selectedOrigin), topFunction()"
          @click:clear="$store.state.originSpace = null"
          @blur="topFunction()"
          :disabled="!this.$store.state.completeAllPath"
          attach="#mid"
        >
        </v-autocomplete>
      </v-row>
      <!--
        Linha onde coloco a selação de Destino de loja
      -->
      <v-row v-if="displayRoute && !hideDestinationBar" class="ma-4">
        <v-autocomplete
          v-model="destination"
          :items="listOfDestinationSpaces"
          item-text="space.name"
          item-value="objectName"
          return-object
          color="black"
          clearable
          outlined
          hide-details
          hide-no-data
          dense
          open-on-clear
          label="Para onde quer ir?"
          :filter="returnHeadersAndFilter"
          @change="$store.commit('setDestination', destination), topFunction()"
          @click:clear="$store.state.destinationSpace = null"
          @blur="topFunction()"
          :disabled="!this.$store.state.completeAllPath"
          attach="#mid"
        ></v-autocomplete>
      </v-row>
      <!--
        Linha com botão para traçar a rota
      -->
      <v-row v-if="displayRoute" class="ma-0 mt-3 pa-0 d-flex justify-end">
        <v-btn
          color="black"
          class="white--text mr-4 mb-4"
          small
          @click="chooseAccessRouteOption()"
          :disabled="
            !this.$store.state.completeAllPath ||
            this.$store.state.originSpace === null ||
            this.$store.state.destinationSpace === null
          "
          >Buscar</v-btn
        >
      </v-row>
    </v-card>
    <v-dialog
      v-if="continuePath"
      v-model="continuePath"
      persistent
      max-width="200"
    >
      <v-card class="d-flex flex-column align-center justify-center">
        <span class="dialogText"
          >Você esta saindo do
          <p class="currentFloor">{{ this.$store.state.currentFloor.name }}</p>
          e indo para o
          <p class="nextFloor">{{ this.nextFloor.name }}</p></span
        >
        <vue-lottie :options="checkFloorLevel()" :height="50" :width="50" />
        <v-btn
          color="green"
          class="white--text ma-4"
          @click="
            $refs.MapComponent.showNextFloorPatch(), (continuePath = false)
          "
          >Continuar</v-btn
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="finishRouteDialog" persistent max-width="200">
      <v-card class="d-flex flex-column align-center justify-center">
        <span class="dialogFinishText mt-2">Você chegou ao seu destino!</span>
        <v-card-actions class="d-flex flex-column align-center justify-center">
          <v-btn
            color="green"
            class="white--text mb-2"
            small
            @click="
              $refs.MapComponent.reloadExclusiveAccess(),
                $refs.MapComponent.loadOriginSpaceMap(),
                $refs.MapComponent.pauseRoute();
              finishRouteDialog = false;
              stopRoute = true;
            "
            >Reiniciar</v-btn
          >
          <v-btn
            color="red"
            class="white--text ml-0 mb-2"
            small
            @click="
              $refs.MapComponent.resetAllRouteValues(),
                (finishRouteDialog = false),
                (stopRoute = true)
            "
            >Finalizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="setAccessRouteDialog" persistent max-width="300">
      <v-card>
        <v-card-title class="dialogFinishText ma-2"
          >Como gostaria de chegar ao seu destino?</v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col align="center">
              <v-card
                width="60"
                height="60"
                elevation="5"
                class="ma-2 d-flex align-center justify-center"
                @click="startRoute()"
              >
                <SVGLoader
                  selected-icon="escalator"
                  class="defaultSVGDesktop"
                  :style="{
                    color: $store.state.mallData.webDestakColor,
                  }"
                ></SVGLoader>
              </v-card>
              <span
                class="subtitle"
                :style="{
                  color: $store.state.mallData.webDestakColor,
                }"
                >Escada</span
              >
            </v-col>
            <v-col align="center">
              <v-card
                width="60"
                height="60"
                elevation="5"
                class="ma-2 d-flex align-center justify-center"
                @click="$refs.MapComponent.accessibilityActive(), startRoute()"
              >
                <SVGLoader
                  selected-icon="elevator"
                  class="defaultSVGDesktop"
                  :style="{
                    color: $store.state.mallData.webDestakColor,
                  }"
                ></SVGLoader>
              </v-card>
              <span
                class="subtitle"
                :style="{
                  color: $store.state.mallData.webDestakColor,
                }"
                >Elevador</span
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import THREEJS from "@/components/THREEJS.vue";
import axios from "axios";
import VueLottie from "vue-lottie";
import upEscalatorIcon from "../../public/assets/up.json";
import downEscalatorIcon from "../../public/assets/down.json";
import SVGLoader from "@/components/SVGLoader.vue";

const _API_MAP = "https://api.trackmall.com.br/mall/";

export default {
  name: "MapViewer",
  components: { THREEJS, VueLottie, SVGLoader },

  data: () => ({
    awsUrl: "https://trackmall.s3.amazonaws.com/", // URL para baixar conteudos na AWS
    allSpaces: [],
    showAllElevators: false, // Botão para mostrar elevadores
    showAllStairs: false, // Botão para mostrar escadas
    accessibility: false, // Botão para ativar acessibilidade
    rightButtons: [], // Lista com todos os botões de acessos
    selectedButton: null, // Botão de andar selecionad
    listOfAllSpaces: [], // Array com todos os andares
    selectedOrigin: null, // Origem selecionada na lista de seleção
    destination: null, // Destino selecionado na lista de seleção
    displayRoute: false, // Controlador para versão mobile, caso true ira mostrar listas de seleção (origem e destino)
    selectedSpaceName: null, // Nome do espaço selecionado
    continuePath: false, // Caixa de dialogo para continuar rota
    hideOrigenBar: false, // Esconder lista de origem
    hideDestinationBar: false, // Esconder lista de destino
    stopRoute: true, // Variavel para determinar que a rota foi pausada
    changeCamera: false, // Variavel para controlar qual a posição da câmera
    nextFloor: null, // Salvando o nome do proximo andar
    lottieOptions: {
      animationData: null, // Caminho do icone
      loop: true, // ou false para reprodução única
      autoplay: true, // reproduz automaticamente ao carregar
    },
    showFilters: false, // Botão para mostrar outros filtros
    finishRouteDialog: false, // Dialog que vai dar opções para finalizar rota ou reiniciar
    setAccessRouteDialog: false, // Dialog para determinar o tipo de acesso escada ou elevador
  }),
  beforeMount() {
    this.$store.state.codeMall = this.$route.params; // Salvo os parametros fornecidos na barra de busca

    // Caso o parametro com o código do shopping seja fornecido
    if (this.$store.state.codeMall.code !== null) {
      // Faço uma requisição na API para receber todas as informações do shopping
      axios
        .get(_API_MAP + this.$store.state.codeMall.code)
        .then((response) => {
          this.$store.state.mallData = response.data.result; // Salvo a resposta na variavel
          if (this.$store.state.mallData) {
            this.sortFloors(); // Executo a função para organizar os botôes de andares
            document.title = this.$store.state.mallData.name;
            this.topFunction();
          } else {
            this.$router.replace("/");
          }
        })
        .catch((error) => {
          this.$router.replace("/");
          console.log(error);
        });
    } else {
      window.alert("Nenhum código fornecido");
    }
  },
  mounted() {},
  methods: {
    // Função para organizar botões do andares
    async sortFloors() {
      // Estou salvando todos os andares e ordenando do menor para o maior
      const floors = this.$store.state.mallData.floors.sort(
        (a, b) => a.level - b.level
      );
      floors.forEach((level) => {
        this.rightButtons.push(level);
      });
      this.setStartFloor(floors); // Executo a função para indicar o andar inicial
      await this.mountListOfAllSpaces(floors); // Executo a função para montar a lista de lojas
    },
    // Função para montar lista de espaços disponiveis
    async mountListOfAllSpaces(floors) {
      return new Promise((result) => {
        let tempList = []; // Array com lista de espaços
        const acceptSpacesType = [
          1, 5, 7, 9, 10, 11, 12, 13, 14, 25, 28, 29, 30, 34, 36, 37, 38, 39,
          40, 41, 42, 43, 44, 45, 46,
        ]; // Tipo de espaços que podem ser selecionados na lista
        for (let i = 0; i < floors.length; i++) {
          this.listOfAllSpaces.push({ header: floors[i].name }); // Adicionando o cabeçalho do andar
          floors[i].floorObjects.forEach((object) => {
            // Caso alguma das opções de espaços esteja na lista de lojas por andar
            if (acceptSpacesType.includes(object.space.spaceType)) {
              tempList.push(object); // Adiciono o andar na array temporária
            }
          });
          // Organizando por ordem alfabética
          tempList.sort((a, b) => {
            const nameA = a.space.name.toUpperCase();
            const nameB = b.space.name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
          // Para cada elemento da array temporária eu adiciono na lista de lojas definitiva já organizada
          tempList.forEach((space) => this.listOfAllSpaces.push(space));
          tempList = [];
          if (i + 1 === floors.length) {
            result();
          }
        }
      });
    },
    // Função para indicar andar inicial
    async setStartFloor(floors) {
      /*
          Caso a origem seja informada e o destino não seja informado ao abrir a pagina
        */
      if (
        this.$store.state.codeMall.origin !== undefined &&
        this.$store.state.codeMall.destino === undefined
      ) {
        // Executo a função de salvar o espaço de origem e salvo o andar
        await this.findOriginSpace(this.$store.state.codeMall.origin);
        if (this.$store.state.originSpace) {
          if (this.$vuetify.breakpoint.mobile) {
            this.$store.state.currentFloor =
              this.$store.state.mallData.floors.find(
                (floor) => floor.level === this.$store.state.originSpace.floor
              ); // Aplico o andar inicial igual a origem
            this.displayRoute = true; // Habilito as listas de escolha de lojas (origem e destino)
          } else {
            this.selectedButton = floors.findIndex(
              (level) => level.level === this.$store.state.originSpace.floor
            ); // Aplico o andar inicial igual a origem
          }
        } else {
          if (this.$vuetify.breakpoint.mobile) {
            this.$store.state.currentFloor = floors.find(
              (level) => level.level === 0
            ); // Andar inicial é sempre o primeiro andar do shopping
          } else {
            this.selectedButton = floors.findIndex(
              (level) => level.level === 0
            );
          }
        }
      } else if (
        /*
          Caso a origem e o destino seja informado ao abrir a pagina
          */
        this.$store.state.codeMall.origin !== undefined &&
        this.$store.state.codeMall.destino !== undefined
      ) {
        await this.findOriginSpace(this.$store.state.codeMall.origin); // Executo a função de salvar o espaço de origem
        // Executo a função de salvar o espaço de destino e salvo o andar
        await this.findDestinationSpace(this.$store.state.codeMall.destino);
        if (this.$store.state.destinationSpace) {
          if (this.$vuetify.breakpoint.mobile) {
            this.$store.state.currentFloor =
              this.$store.state.mallData.floors.find(
                (floor) =>
                  floor.level === this.$store.state.destinationSpace.floor
              ); // Aplico o andar inicial igual a origem
            this.displayRoute = true; // Habilito as listas de escolha de lojas (origem e destino)
          } else {
            this.selectedButton = floors.findIndex(
              (level) =>
                level.level === this.$store.state.destinationSpace.floor
            ); // Aplico o andar inicial igual a origem
          }
        } else {
          if (this.$vuetify.breakpoint.mobile) {
            this.$store.state.currentFloor = floors.find(
              (level) => level.level === 0
            ); // Andar inicial é sempre o primeiro andar do shopping
          } else {
            this.selectedButton = floors.findIndex(
              (level) => level.level === 0
            );
          }
        }
      } else if (
        /*
          Caso o destino seja informado e a origem não seja informada
        */
        this.$store.state.codeMall.origin === undefined &&
        this.$store.state.codeMall.destino !== undefined
      ) {
        // Executo a função de salvar o espaço de origem e salvo o andar
        await this.findDestinationSpace(this.$store.state.codeMall.destino);
        if (this.$store.state.destinationSpace) {
          if (this.$vuetify.breakpoint.mobile) {
            this.$store.state.currentFloor =
              this.$store.state.mallData.floors.find(
                (floor) =>
                  floor.level === this.$store.state.destinationSpace.floor
              ); // Aplico o andar inicial igual a origem
            this.displayRoute = true; // Habilito as listas de escolha de lojas (origem e destino)
          } else {
            this.selectedButton = floors.findIndex(
              (level) =>
                level.level === this.$store.state.destinationSpace.floor
            ); // Aplico o andar inicial igual a origem
          }
        } else {
          if (this.$vuetify.breakpoint.mobile) {
            this.$store.state.currentFloor = floors.find(
              (level) => level.level === 0
            ); // Andar inicial é sempre o primeiro andar do shopping
          } else {
            this.selectedButton = floors.findIndex(
              (level) => level.level === 0
            );
          }
        }
      }
      // Caso nem a origem e nem o destino seja informado
      else {
        if (this.$vuetify.breakpoint.mobile) {
          this.$store.state.currentFloor =
            this.$store.state.mallData.floors.find((level) => {
              if (level.level === 0) {
                return true;
              }
            }); // Andar inicial é sempre o primeiro andar do shopping
        } else {
          this.selectedButton = floors.findIndex((level) => level.level === 0);
        }
      }
    },
    // Função para buscar na lista de lojas e salvar como ponto de origem
    async findOriginSpace(space) {
      return new Promise((result) => {
        // Em cada andar do shopping, procuro pelo spaceId que seja igual ao paremetro e salvo como origem fixa
        this.$store.state.mallData.floors.forEach((floor) => {
          if (!this.$store.state.originSpace) {
            floor.floorObjects.find((object) => {
              if (
                object.spaceId === parseInt(space) ||
                object.space.code === space ||
                object.space.customerId === space
              ) {
                this.selectedOrigin = object; // Salvo como origem na lista de seleção
                this.selectedSpaceName = object; // Salvo a loja para mostrar no card
                this.$store.commit("setOrigin", this.selectedOrigin); // Salvo como origem fixa
                result();
                return true;
              } else {
                this.$store.state.originSpace = null;
                result();
              }
            });
          }
        });
      });
    },
    // Função para buscar na lista de lojas e salvar como ponto de destino
    async findDestinationSpace(space) {
      return new Promise((result) => {
        // Em cada andar do shopping, procuro pelo spaceId que seja igual ao paremetro e salvo como destino fixo
        this.$store.state.mallData.floors.forEach((floor) => {
          if (!this.$store.state.destinationSpace) {
            floor.floorObjects.find((object) => {
              if (
                object.spaceId === parseInt(space) ||
                object.space.code === space ||
                object.space.customerId === space
              ) {
                this.destination = object; // Salvo como destino na lista de seleção
                this.$store.commit("setDestination", this.destination); // Salvo como destino fixo
                result();
                return true;
              } else {
                this.$store.state.destinationSpace = null;
                result();
              }
            });
          }
        });
      });
    },
    // Função para trocar o destino na lista de seleção, quando o usuário seleciona o espaço tocando no mapa
    changeDestination(spaceName) {
      this.destination = spaceName;
    },
    // Função para trocar a origem na lista de seleção, quando o usuário seleciona o espaço tocando no mapa
    changeOrigin(spaceName) {
      this.selectedOrigin = spaceName;
    },
    // Função que vai procurar o nome da loja de acordo com o espaço que o mouse passou por cima no mapa
    showSpaceName(spaceName) {
      if (
        spaceName === null &&
        this.$store.state.originSpace &&
        !this.$store.state.destinationSpace &&
        !this.$store.state.inRoute
      ) {
        this.selectedSpaceName =
          this.$store.state.currentFloor.floorObjects.find(
            (space) => space.spaceId === this.$store.state.originSpace.spaceId
          );
      } else {
        this.selectedSpaceName =
          this.$store.state.currentFloor.floorObjects.find(
            (space) => space.space.name === spaceName
          );
      }
    },
    // Função que vai trocar o andar, quando o usuário escolhe um espaço diferente do andar atual
    toggleFloor(floor) {
      this.$store.state.completeAllPath = false;
      // Caso seja versão desktop
      if (!this.$vuetify.breakpoint.mobile) {
        this.selectedButton = this.rightButtons.findIndex(
          (level) => level.level === floor
        ); // Indico qual o botão do andar deve estar selecionado
      }
      // Caso seja versão mobile
      else {
        // Salvo o andar atual de acordo com o número do andar fornecido em floor
        this.$store.state.currentFloor = this.$store.state.mallData.floors.find(
          (level) => level.level === floor
        );
      }
    },
    // Função para filtrar lojas e manter os cabeçalhos
    returnHeadersAndFilter(item, queryText, itemText) {
      if (item.header) {
        return true;
      }
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    // Função para mover a pagina para o topo
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    toggleCameraButton(status) {
      this.changeCamera = status;
    },
    // Função para mudar a velocidade
    setSpeed() {
      switch (this.$store.state.personSpeed) {
        case 3:
          this.$store.state.personSpeed = 6;
          break;

        case 6:
          this.$store.state.personSpeed = 9;
          break;

        case 9:
          this.$store.state.personSpeed = 3;
          break;
      }
    },
    // Função para salvar o nome do proximo andar
    saveNextFloorName(name) {
      this.rightButtons.find((level) => {
        if (level.level === name) {
          this.nextFloor = level;
          return true;
        }
      });
    },
    // Função para determinar qual o icone será carregado
    checkFloorLevel() {
      if (this.nextFloor.level > this.$store.state.currentFloor.level) {
        this.lottieOptions.animationData = upEscalatorIcon;
        return this.lottieOptions;
      } else {
        this.lottieOptions.animationData = downEscalatorIcon;
        return this.lottieOptions;
      }
    },
    // Função para habilitar caixa de dialogo com opções de acesso de acordo com origem/destino
    chooseAccessRouteOption() {
      if (
        this.$store.state.originSpace.floor !==
        this.$store.state.destinationSpace.floor
      ) {
        this.setAccessRouteDialog = true;
      } else {
        this.startRoute();
      }
    },
    // Função para iniciar o calculo de rota
    startRoute() {
      this.setAccessRouteDialog = false;

      if (this.$vuetify.breakpoint.mobile) {
        this.$refs.MapComponent.loadOriginSpaceMap();
        this.$refs.MapComponent.pauseRoute();
        this.displayRoute = false;
      } else {
        this.$refs.MapComponent.loadOriginSpaceMap();
        this.$refs.MapComponent.pauseRoute();
      }
    },
  },
  watch: {
    // Monitoramente da variavel
    selectedButton() {
      // Caso o botão do andar seja alterado
      this.$store.state.completeAllPath = false; // Travo todos os botões
      // Caso seja versão desktop
      if (!this.$vuetify.breakpoint.mobile) {
        // Aplico o novo andar
        this.$store.state.currentFloor =
          this.$store.state.mallData.floors[this.selectedButton];
      }
    },
    // Monitoramente da variavel para buscar se a origem existe na lista
    selectedOrigin() {
      if (this.selectedOrigin !== null) {
        if (
          this.listOfAllSpaces.find(
            (spaces) => spaces.objectName === this.selectedOrigin.objectName
          )
        ) {
          this.hideOrigenBar = false;
        } else {
          this.hideOrigenBar = true;
        }
      } else {
        this.hideOrigenBar = false;
      }
    },
    // Monitoramente da variavel para buscar se o destino existe na lista
    destination() {
      if (this.destination !== null) {
        if (
          this.listOfAllSpaces.find(
            (spaces) => spaces.objectName === this.destination.objectName
          )
        ) {
          this.hideDestinationBar = false;
        } else {
          this.hideDestinationBar = true;
        }
      } else {
        this.hideDestinationBar = false;
      }
    },
  },
  computed: {
    // Montando a lista de lojas para origem, retirando a loja que foi selecionada no destino
    listOfOrigenSpaces() {
      return this.listOfAllSpaces.filter((space) => space !== this.destination);
    },
    // Montando a lista de lojas para destino, retirando a loja que foi selecionada na origem
    listOfDestinationSpaces() {
      return this.listOfAllSpaces.filter(
        (space) => space !== this.selectedOrigin
      );
    },
    setSpeedIcon() {
      return () => {
        switch (this.$store.state.personSpeed) {
          case 3:
            return "mdi-speedometer-slow";

          case 6:
            return "mdi-speedometer-medium";

          case 9:
            return "mdi-speedometer";
        }
      };
    },
  },
};
</script>
<style>
#app {
  overflow-x: hidden;
}
.floorTextActive {
  margin: 16px;
  font-size: 16px;
  font-weight: 500;
  text-wrap: nowrap;
}
.floorTextDeActive {
  margin: 16px;
  font-size: 16px;
  font-weight: 500;
  text-wrap: nowrap;
}
.traceRouteCard {
  position: absolute;
  z-index: 1;
  margin: 8px;
}
.mobileCard {
  position: fixed;
  width: -webkit-fill-available;
  top: 100%;
  transform: translateY(-100%);
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}
.progressBar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.actionsButtonsDesktop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  margin: 0px;
  padding: 0px;
}
.actionsButtonsMobile {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
}
.floorSelection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  z-index: 1;
  margin-right: 60px;
  margin-top: 20px;
}
.floorSelectionMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
#defaultAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
}
#defaultAction:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px;
  height: 60px;
  width: 60px;
}
#selectedAction {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border: 2px;
  height: 60px;
  width: 60px;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-items: center;
}
#spaceNameContainer {
  top: 10%;
  left: 50%;
  transform: translate(-70%, -40%);
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 15px 0px #0000002a;
  background-color: rgba(255, 255, 255, 0.796);
  z-index: 1;
}
.showCameraButton {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
  background-color: white !important;
  border-radius: 10px !important;
}
.hiddenCameraButton {
  display: none;
}
.v-subheader.theme--light {
  background-color: #005d3e;
  color: white;
  font-weight: bold;
  font-size: large;
}
.speedText {
  position: absolute;
  top: 100%;
  font-size: 12px;
  font-weight: 700;
  transform: translateY(-5px);
}
.speedTextMobile {
  position: absolute;
  top: 65%;
  font-size: 12px;
  font-weight: 700;
  transform: translateY(-13px);
}
.subtitle {
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
}
.subtitleActive {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
}
.subtitleAccessibility {
  font-size: 9px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
}
.subtitleActiveAccessibility {
  font-size: 9px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
}
.dialogText {
  margin: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}
.dialogFinishText {
  margin: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.currentFloor {
  color: #005d3e;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 4px !important;
}
.nextFloor {
  color: red;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 4px !important;
}
.floorCards {
  display: flex;
  justify-content: end;
  padding: 0px;
  overflow-y: auto;
  max-height: 400px;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #005d3e;
  border-radius: 50px;
}
.defaultSVGDesktop {
  width: 40px;
  height: 40px;
}
.activeSVGDesktop {
  width: 40px;
  height: 40px;
}
.defaultSVGMobile {
  width: 20px;
  height: 20px;
  margin-top: 8px;
}
.activeSVGMobile {
  width: 20px;
  height: 20px;
  margin-top: 8px;
}
.spaceCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 0px !important;
}
.normalCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.autocompletePosition {
  position: absolute;
  left: 5%;
  bottom: 240%;
  z-index: 2;
}
.menuContent {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 80px !important;
}
</style>
