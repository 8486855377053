<template>
  <v-container class="map">
    <v-container id="mapContainer" v-if="$vuetify.breakpoint.mobile">
    </v-container>
    <canvas
      id="canvas"
      :class="!$vuetify.breakpoint.mobile ? null : 'mobile'"
    ></canvas>
  </v-container>
</template>

<script>
import WebGL from "three/examples/jsm/capabilities/WebGL.js";
import * as THREE from "three";
//import TWEEN from "@tweenjs/tween.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils.js";
import { Pathfinding, PathfindingHelper } from "three-pathfinding";

export default {
  name: "THREEJS",

  data: () => ({
    // Variaveis para THREE.JS
    renderer: null, // Renderizador do THREE.JS
    TextureLoader: null, // Loader de Textura
    raycaster: null, // Pegar ponto de intersecção no toque da tela
    mouse: null, // Pegar posição X e Y no momento do toque
    mapScene: null, // Cena principal com mapa, luzes, camera, espaços
    mapCamera: null, // Câmera principal
    mapLight: null, // Luz principal
    ambientLight: null, // Luz ambiente
    allObject3DGroup: null, // Grupo com todos os objetos 3D que o mapa contem
    control: null, // Função de controle
    orbitCam: new THREE.Vector3(), // Vetor para capturar posição do mapa/camera
    minPan: new THREE.Vector3(), // Limitando a movimentação do mapa na tela
    maxPan: new THREE.Vector3(), // Limitando a movimentação do mapa na tela
    focusCam: null, // Camera utilizada para focar na loja/objeto
    focusCamOrbitCam: null, // Camera orbital para dar movimento a camera de foco
    targetQuaternion: null, // Variavel para definir rotação do personagem
    rotationMatrix: null, // Variavel para definir rotação do personagem

    // Variaveis para Path Finder
    navmesh: null, // Malha para navegação
    pathfinding: null, // Função para criar rota
    pathfindinghelper: null, // Função para vizualizar rota criada com configuração padrao do pathfinder
    ZONE: null, // Nome da ZONA que vamos navegar
    groupID: null, // Numero do grupo para navegação vinculado com a ZONA e navmesh
    navpath: [], // Caminho encontrado
    clock: null, // Relógio para criar as animações

    // Variaveis para banco de dados
    awsUrl: "https://trackmall.s3.amazonaws.com/",
    currentMap: null, // Mapa Atual
    destinationFloor: null, // Andar do destino selecionado
    selectedSpace: null, // Espaço no qual o mouse passou por cima
    selectedSpaceName: null, // Nome do espaço atual
    originSpace: null, // Loja de origem
    destinationSpace: null, // Loja de destino

    // Variaveis para manipulação do mapa
    showAllStairs: false, // Mostrar todas as escadas
    showAllElevator: false, // Mostrar todos os elevadores
    thirdPersonView: false, // True: Visão em terceira pessoa, False: Visão por cima
    canvasContainer: null, // Canvas para adicionar/remover eventos
    personToWalk: null, // Personagem que vai andar no mapa
    animationPerson: null, // Animação do personagem
    tubeToShowRoute: null, // Variavel que vai armazenar o tubo;
    stepToMountTubeRoute: 50, // Etapas que o objeto de rota sera montado
    endStepToMountTubeRoute: 0, // Valor o ultima etapa
    maxStepCount: null, // Maximo de estapas

    // Variavel para definições de andares e rotas
    oldExclusiveAccess: null, // Salvo o ultimo acesso exclusivo caso o usuario deseje reiniciar a rota
    exclusiveAccess: null, // Salvo o objeto 3D de acesso exclusivo
    exclusiveRoute: false, // Variavel para determinar que a rota é uma rota exclusiva
    routeUsingElevator: false, // Variavel para determinar se a rota ira utilizar elevador ou não
    newRouteToNextFloorToElevator: false, // Variavel para determinar o load do proximo andar e tentar acessar o elevador
    floorsConnections: [], // Array para armazenar andares que se conectam
    originStairsPath: [], // Array que vai armazenar a distancia de cada escada para o ponto de origem
    destinationStairsPath: [], // Array que vai armazenar a distancia de cada escada para o ponto de destino
  }),
  mounted() {
    // Verificação de compatibilidade do navegador com WebGL
    if (WebGL.isWebGLAvailable()) {
      this.init3D();
      if (this.$vuetify.breakpoint.mobile) {
        this.createMapDiv();
      }
    } else {
      const warning = WebGL.getWebGLErrorMessage();
      window.alert(warning);
    }
  },
  methods: {
    /**
     *  Funções de inicialização
     *
     *  @function init3D Inicia todos os componentes do THREE.JS
     *  @function createMapDiv Cria o container onde o mapa ira ser renderizado
     *  @function removeMousePosition Define a posição do mouse para fora da tela
     *  @function onHover Função que vai identificar posição do mouse na tela do computador
     *  @function onTouch Função que vai identificar posição tocada na tela do celular
     *  @function onWindowResize Função para identificar alteração do tamanho da tela e ajustar THREE.JS
     *  @function removeEventsListeners Remover os eventos da tela
     *  @function addEventsListeners Adicionar os eventos da tela
     *
     */
    init3D() {
      THREE.Cache.enabled = true;
      // Inicializador do WEBGL
      this.renderer = new THREE.WebGLRenderer({
        canvas: document.querySelector("canvas"),
        alpha: true,
        antialias: true,
      });

      // Configurações das ferramentas para cena
      this.mapScene = new THREE.Scene(); // Criando uma cena
      this.mapCamera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        1000
      ); // Criando uma câmera para a cena
      this.focusCam = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        1000
      ); // Criando uma câmera específica para a loja selecionada e objeto em movimento
      this.focusCam.layers.enable(1); // Definindo qual as layers que essa camera pode vizualizar
      this.focusCam.layers.enable(-1); // Definindo qual as layers que essa camera pode vizualizar
      this.mapLight = new THREE.DirectionalLight(0xffffff, 1.5); // Adicionando uma luz direcional para criar sombras
      this.ambientLight = new THREE.AmbientLight(0xffffff, 1.5); // Adicionando uma luz ambiente
      this.raycaster = new THREE.Raycaster(); // Criando o interpretador de toques tela / ambiente 3D
      this.mouse = new THREE.Vector2(); // Criando vetores X e Y para capturar local tocado na tela
      this.pathfinding = new Pathfinding(); // Criando função para encontrar o caminho
      this.pathfindinghelper = new PathfindingHelper(); // Criando função para vizualizador de caminho encontrado
      this.clock = new THREE.Clock(); // Criando relógio para animações em tempo real
      this.targetQuaternion = new THREE.Quaternion(); // Criando função para pegar rotação do objeto alvo
      this.rotationMatrix = new THREE.Matrix4(); // Criando função para definir rotação do personagem
      // Configurações de tela do renderer
      this.renderer.setPixelRatio(window.devicePixelRatio); // Definindo o aspecto da tela
      this.renderer.setSize(window.innerWidth, window.innerHeight); // Definindo o tamanho da tela
      this.renderer.shadowMap.enabled = true; // Habilitando o gerador de sombras
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Definindo o tipo de sombra que será gerado
      this.clock.autoStart = false; // Definindo o status inicial do relógio como false, para controle manual

      // Configurações da Cena
      this.mapScene.name = "Map Scene"; // Alterando o nome da cena
      this.mapCamera.layers.enable(1); // Habilitando apenas a vizualização da layer 1
      this.mapCamera.layers.enable(-1); // Habilitando apenas a vizualização da layer -1 dos logos

      // Configurações de luzes
      this.mapLight.position.set(0, 40, 0); // Definindo posição da luz
      this.mapLight.castShadow = true; // Habilitando para essa luz gerar sombra nos elementos
      this.mapLight.shadow.mapSize.width = 2048; // Definindo a qualidade da sombra
      this.mapLight.shadow.mapSize.height = 2048; // Definindo a qualidade da sombra
      this.mapLight.shadow.camera.near = 5; // Definindo a distancia minima de transmissão de luz
      this.mapLight.shadow.camera.far = 50; // Definindo a distancia máxima de transmissão de luz
      this.mapLight.shadow.camera.left = -150; // Definindo o tamanho da luz
      this.mapLight.shadow.camera.right = 150; // Definindo o tamanho da luz
      this.mapLight.shadow.camera.top = 100; // Definindo o tamanho da luz
      this.mapLight.shadow.camera.bottom = -100; // Definindo o tamanho da luz

      this.loadPersonage();

      // Adicionando ferramentas na cena
      this.mapScene.add(this.ambientLight); // Adicionando luz ambiente
      this.mapScene.add(this.mapLight); // Adicionando luz direcionando
      this.mapScene.userData.element = document.querySelector("canvas"); // Vinculando o canvas (domElement) na cena criada

      // Configurações do controle da câmera na tela
      this.control = new OrbitControls(
        this.mapCamera,
        this.mapScene.userData.element
      ); // Criando um novo tipo de controle orbital (envolta da cena)

      this.control.screenSpacePanning = true; // Habilita a movimentação com efeito de smooth
      this.control.minPolarAngle = (-80 * Math.PI) / 180.0; // Distancima minima de rotação eixo X (não ve parte debaixo do mapa)
      this.control.maxPolarAngle = (80 * Math.PI) / 180.0; // Distancima máxima de rotação eixo X (não ve parte debaixo do mapa)

      this.control.minAzimuthAngle = (-80 * Math.PI) / 180.0; // Limitador do eixo Y
      this.control.maxAzimuthAngle = (80 * Math.PI) / 180.0; // Limitador do eixo Y

      this.control.mouseButtons = {
        LEFT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: THREE.MOUSE.ROTATE,
      }; // Definindo o tipo de clique no mapa, botão esquerdo move mapa, direito gira mapa
      this.control.touches.ONE = THREE.TOUCH.PAN; // Definindo o tipo do toque e sua função 1 dedo movimenta
      this.control.touches.TWO = THREE.TOUCH.DOLLY_ROTATE; // Definindo o tipo do toque e sua função 2 dedos gira

      // Configurações para limitar a movimentação do mapa na tela
      this.control.addEventListener("change", () => {
        this.orbitCam.copy(this.control.target);
        this.control.target.clamp(this.minPan, this.maxPan);
        this.orbitCam.sub(this.control.target);
        this.mapCamera.position.sub(this.orbitCam);
      });

      this.mapScene.userData.controls = this.control; // Adicionando o controle da cena

      window.addEventListener("resize", this.onWindowResize); // Monitoramento da mudança do tamanho da tela
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          this.renderer.enabled = false;
        } else {
          this.renderer.enabled = true;
          this.renderer.forceContextRestore();
          window.location.reload(true);
        }
      });

      this.canvasContainer = document.querySelector("canvas");
      this.addEventsListeners();

      this.mountFloorsConnections(); // Função para montar a array com as conexões dos andares

      setTimeout(() => {
        this.loadMap();
      }, 500);
    },
    createMapDiv() {
      // Definindo o tamanho do canvas caso seja um celular
      const mapContainer = document.getElementById("mapContainer");
      mapContainer.style.height = "100%";
      mapContainer.style.padding = "0px";

      const element = document.createElement("div");
      element.className = "Map Container";
      element.style.height = "100%";

      const sceneElement = document.createElement("div");
      sceneElement.style.height = "100%";
      element.appendChild(sceneElement);

      this.mapScene.userData.element = sceneElement;
      mapContainer.appendChild(element);
    },
    removeMousePosition() {
      // Definindo a posição do mouse para fora da tela
      this.mouse.x = 0;
      this.mouse.y = 860;
    },
    onHover(event) {
      event.preventDefault();

      // Salvando a posição do mouse
      this.mouse.x = (event.offsetX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.offsetY / window.innerHeight) * 2 + 1;
    },
    onTouch(event) {
      if (this.$vuetify.breakpoint.mobile) {
        const rect = this.mapScene.userData.element.getBoundingClientRect();
        this.mouse.x =
          ((event.touches[0].clientX - rect.left) / (rect.right - rect.left)) *
            2 -
          1;
        this.mouse.y =
          -((event.touches[0].clientY - rect.top) / (rect.bottom - rect.top)) *
            2 +
          1;
        this.mobileSelection();
      }
    },
    onWindowResize() {
      this.mapCamera.aspect = window.innerWidth / window.innerHeight;
      this.mapCamera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    removeEventsListeners() {
      // Caso seja versão mobile
      if (this.$vuetify.breakpoint.mobile) {
        // Removo evento de toque na tela
        this.canvasContainer.removeEventListener("touchstart", this.onTouch);
        this.canvasContainer.removeEventListener(
          "touchend",
          this.removeMousePosition
        );
      }
      // Caso seja versão desktop
      else {
        // Removo evento de passar o mouse por cima do espaço
        this.canvasContainer.removeEventListener("mousemove", this.onHover);
        this.canvasContainer.removeEventListener(
          "mouseout",
          this.removeMousePosition
        );
      }
    },
    addEventsListeners() {
      if (!this.$vuetify.breakpoint.mobile) {
        this.canvasContainer.addEventListener("mousemove", this.onHover); // Monitoramento ao passar o mouse sobre a loja
        this.canvasContainer.addEventListener(
          "mouseout",
          this.removeMousePosition
        ); // Vou tirar a posição x e y do mouse para que o usuario não toque em alguma loja ao entrar nos outros cards
      } else {
        this.canvasContainer.addEventListener("touchstart", this.onTouch); // Monitoramente ao tocar na tela
        this.canvasContainer.addEventListener(
          "touchend",
          this.removeMousePosition
        ); // Vou tirar a posição x e y para que ao mover o mapa não selecione uma loja nova
      }
    },

    /**
     *
     * Funções de Loaders do Mapa e Materiais
     *
     *  @function loadMap Carregar mapa, textura do mapa e malha de navegação
     *  @function loadPersonage Carregar objeto 3D de pessoa ou criar elemento 3D de esfera
     *  @function setSelectable Definir quais lojas podem ser selecionadas
     *  @function getMapSize Função para definir limites de movimentação de tela + posicionamento da câmera referente ao tamanho do mapa
     *  @function loadMarkData Carregar textura do piso, pictogramas e logos de lojas
     *
     */

    async loadMap() {
      // Caso já possua algum mapa, o mesmo é removido e removido todas configurações da malha
      if (this.currentMap) {
        await this.cleanMap();
      }

      // Carregando textura MTL
      const mtl = new MTLLoader();
      mtl.load(
        this.awsUrl + this.$store.state.currentFloor.mtlUrl,
        (material) => {
          material.preload(); // Pré carregando o MTL
          const obj = new OBJLoader();
          obj.setMaterials(material); // Aplicando o material no objeto que será carregado
          obj.load(
            this.awsUrl + this.$store.state.currentFloor.objUrl,
            async (floor) => {
              this.$store.state.percentLoaded = 0;
              floor.rotateX(Math.PI / -2); // Aplicando rotação do mapa
              this.currentMap = floor; // Definindo o mapa atual
              this.currentMap.visible = false;
              // Para cada children dentro do OBJ carregado será executado uma função
              this.mapScene.add(floor); // Adicionar o andar na cena
              await this.loadMarksData(); // Função para carregar logos ou pictogramas
              await this.setSelectable(); // Função para determinar caracteristicas de cada espaço no mapa
              await this.getMapSize(); // Função para determinar o tamanho do mapa e ajudar posicionamento da câmera
            },
            (xhr) => {
              this.$store.state.percentLoaded = (xhr.loaded / xhr.total) * 100;
            },
            (error) => {
              console.log("Erro ao carregar mapa", error);
            }
          );
        },
        (xhr) => {
          xhr;
        },
        (error) => {
          console.log("Erro ao carregar textura do piso", error);
        }
      );

      // Carregando a malha de navegação
      const gltLoader = new GLTFLoader(); // Criando o loader de arquivos GLTF
      const dracoLoader = new DRACOLoader(); // Criando o compressor de geometrias
      dracoLoader.setPath("/examples/jsm/libs/draco/");
      gltLoader.setDRACOLoader(dracoLoader);
      gltLoader.load(
        this.awsUrl + this.$store.state.currentFloor.gltfMeshUrl,
        (gltf) => {
          const navmesh = gltf.scene;
          navmesh.name = "Malha";
          navmesh.rotateX(Math.PI / -2); // Aplicando a mesma rotação na malha de navegação com o mapa
          //this.showNavMesh(navmesh);
          gltf.scene.traverse((node) => {
            if (
              !this.navmesh &&
              node.isObject3D &&
              node.children &&
              node.children.length > 0
            ) {
              this.ZONE = node.children[0].name; // Definindo o nome da ZONE que será utilizada no pathfinder
              this.navmesh = node.children[0]; // Definindo a malha de navegação
              this.pathfinding.setZoneData(
                this.ZONE,
                Pathfinding.createZone(this.navmesh.geometry)
              );
            }
          });
        },
        (xhr) => {
          xhr;
        },
        (error) => {
          console.log("Erro ao carregar malha de navegação", error);
        }
      );
      this.animate();
    },
    async loadMarksData() {
      return new Promise((resolve) => {
        for (
          let i = 0;
          i < this.$store.state.currentFloor.floorMarks.length;
          i++
        ) {
          let checkType = null;
          // Caso exista o objeto carregado no arquivo OBJ na lista de objeto fornecido pelo banco o mesmo é salvo
          this.currentMap.children.find((object) => {
            if (
              object.name ===
              this.$store.state.currentFloor.floorMarks[i].objectName
            ) {
              return (checkType = this.$store.state.currentFloor.floorMarks[i]);
            }
          });
          if (checkType) {
            const space = this.currentMap.getObjectByName(checkType.objectName); // Procuro o objeto 3D no mapa com o mesmo nome
            const spaceBox = new THREE.Box3().setFromObject(space); // Crio uma caixa  virtual a partir do espaço encontrado
            const centerSpace = spaceBox.getCenter(new THREE.Vector3()); // Pego o centro dessa caixa
            const pictogramMaterial = new THREE.Color(
              this.$store.state.mallData.webPictogramColor
            );
            const pictogramBorder = new THREE.Color("#ffffff");
            switch (checkType.markType) {
              // Adicionando os pictogramas no mapa
              case 1:
                var mtl = new MTLLoader();
                mtl.load(
                  this.awsUrl + checkType.pictogram.mtlUrl,
                  (materials) => {
                    materials.nameLookup.name = checkType.pictogram.name;
                    materials.preload();
                    const obj = new OBJLoader();
                    obj.setMaterials(materials);
                    obj.load(
                      this.awsUrl + checkType.pictogram.objUrl,
                      async (pictogram) => {
                        pictogram.position.copy(centerSpace); // Defino a posição do pictograma
                        pictogram.scale.set(
                          checkType.size,
                          checkType.size,
                          checkType.size
                        ); // Defino a escala do pictograma
                        pictogram.translateX(checkType.offsetX); // Defino a posição X do pictograma acima do objeto
                        pictogram.translateY(checkType.offsetY); // Defino a posição Y do pictograma acima do objeto
                        pictogram.translateZ(spaceBox.max.z); // Defino a posição Z do pictograma acima do objeto
                        pictogram.rotateX(1); // Aplico uma rotação no pictograma
                        pictogram.userData.locked = true; // Travo ele para não aplicar nenhuma textura
                        pictogram.userData.typeObj = 1; // Salvando o tipo do objeto
                        if (pictogram.children[0].material.length > 0) {
                          pictogram.children[0].material.forEach((material) => {
                            if (material.color.r === 0.035599644910088456) {
                              material.color = pictogramMaterial;
                            } else {
                              material.color = pictogramBorder;
                            }
                          });
                        } else {
                          pictogram.children[0].material.color =
                            pictogramMaterial;
                        }
                        space.add(pictogram); // Adiciono o pictograma dentro do objeto encontrado
                      }
                    );
                  }
                );
                break;
              // Adicionando logo das lojas
              case 3:
                if (checkType.fileUrl) {
                  var textureLoader = new THREE.TextureLoader(); // Criando o loader de Texturas
                  textureLoader.colorSpace = THREE.SRGBColorSpace; // Aplicando o tipo de Cores
                  // Carrego o logo da loja
                  textureLoader.load(
                    this.awsUrl + checkType.fileUrl,
                    (texture) => {
                      const width = texture.source.data.width;
                      const height = texture.source.data.height;
                      let planeW = null;
                      let planeH = null;
                      const aspect = (height / width).toFixed(1);
                      if (aspect < 0.5) {
                        switch (aspect) {
                          case "0.1":
                            planeW = checkType.size;
                            planeH = checkType.size / 5;
                            break;

                          case "0.2":
                            planeW = checkType.size;
                            planeH = checkType.size / 4;
                            break;

                          case "0.3":
                            planeW = checkType.size;
                            planeH = checkType.size / 3;
                            break;

                          case "0.4":
                            planeW = checkType.size;
                            planeH = checkType.size / 2;
                            break;
                        }
                      } else {
                        planeW = checkType.size;
                        planeH = checkType.size;
                      }
                      var plane = new THREE.PlaneGeometry(planeW, planeH); // Crio uma estrutura plana
                      var material = new THREE.MeshBasicMaterial({
                        map: texture,
                        transparent: true,
                      });
                      var mesh = new THREE.Mesh(plane, material); // Adiciono como textura o logo na estrutura plana
                      mesh.position.copy(centerSpace); // Defino a posição da estrutura plana
                      mesh.translateX(checkType.offsetX); // Defino a posição no eixo X da estrutura plana
                      mesh.translateY(checkType.offsetY); // Defino a posição no eixo Y da estrutura plana
                      mesh.translateZ(spaceBox.max.z); // Defino a posição no eixo Z da estrutura plana
                      mesh.layers.set(-1); // Defino o layer diferente das lojas para não selecionar o logo por engano
                      mesh.userData.locked = true; // Travo ele para não aplicar nenhuma textura
                      mesh.userData.typeObj = 3; // Salvando o tipo do objeto
                      space.add(mesh); // Adiciono a estrutura dentro do objeto encontrado
                    }
                  );
                }
                break;

              // Aplicando textura no chão do piso
              case 4:
                if (checkType.fileUrl) {
                  var textureFloorLoader = new THREE.TextureLoader(); // Criando o loader de Texturas
                  textureFloorLoader.colorSpace = THREE.SRGBColorSpace; // Aplicando o tipo de Cores
                  // Carrego a textura do chão
                  textureFloorLoader.load(
                    this.awsUrl + checkType.fileUrl,
                    (texture) => {
                      // Crio um material com a textura carregada
                      const material = new THREE.MeshBasicMaterial({
                        map: texture,
                      });
                      // Procuro o mapa e aplico o material nele
                      this.currentMap.getObjectByName(
                        checkType.objectName
                      ).material = material;
                      // Aplico para o chão receber sombra de objetos
                      this.currentMap.getObjectByName(
                        checkType.objectName
                      ).receiveShadow = true;
                      this.currentMap.visible = true; // Deixo ele visivel
                      this.currentMap.getObjectByName(
                        checkType.objectName
                      ).userData.locked = true; // Travo ele para não aplicar nenhuma textura
                      this.currentMap.userData.typeObj = 4; // Salvando o tipo do objeto
                      resolve();
                    }
                  );
                } else {
                  this.currentMap.visible = true; // Deixo ele visivel
                  resolve();
                }
                break;
            }
          }
          let checkFloorMaterial =
            this.$store.state.currentFloor.floorMarks.find(
              (type) => type.markType === 4
            );

          if (
            i + 1 === this.$store.state.currentFloor.floorMarks.length &&
            !checkFloorMaterial
          ) {
            this.currentMap.visible = true;
            resolve();
          }
        }
      });
    },
    async setSelectable() {
      return new Promise((resolve) => {
        for (let i = 0; i < this.currentMap.children.length; i++) {
          // Caso exista o objeto carregado no arquivo OBJ na lista de objeto fornecido pelo banco o mesmo é salvo
          let objectSpace = null;
          this.$store.state.currentFloor.floorObjects.find((object) => {
            if (object.objectName === this.currentMap.children[i].name) {
              objectSpace = object;
            }
          });

          // Criando material para todos os espaço selecionaveis
          const spaceMaterial = new THREE.MeshPhongMaterial({
            color: new THREE.Color(
              this.$store.state.currentFloor.activeSpaceColorWeb
            ),
            specular: 0x111111,
            shininess: 100,
          });
          // Criando material para todos os espaço não selecionaveis antiga cor 0x325d32
          const defaultMaterial = new THREE.MeshPhongMaterial({
            color: new THREE.Color(
              this.$store.state.currentFloor.inactiveSpaceColorWeb
            ),
            specular: 0x222222,
            shininess: 100,
          });
          if (objectSpace) {
            switch (objectSpace.space.spaceType) {
              /* 
                1 = Lojas -> Ativo
                2 = Diretórios -> Ativo
                3 = Escadas Rolantes -> Ativo
                4 = Escadas Fixas
                5 = Elevadores -> Ativo
                6 = Passarelas
                7 = Sanitários -> Ativo
                8 = Praça de Alimentação
                9 = Cinema -> Ativo
                10 = Teatro -> Ativo
                11 = Fraldários -> Ativo
                12 = Caixas eletrônicos -> Ativo
                13 = Ambulatórios -> Ativo
                14 = Achados e Perdidos -> Ativo
                15 = Ponto de encontro
                16 = Carrinhos de bebês
                17 = Ponto de Taxi
                18 = Wi-Fi Zone
                19 = Centro ecumênico
                20 = Transporte Público
                21 = Câmbio
                22 = Estacionamento
                23 = Concierge
                25 = Sanitário acessível -> Ativo
                26 = Saída
                27 = Caixa de estacionamento
                28 = Sanitário Masculino -> Ativo
                29 = Sanitário Feminino -> Ativo
                30 = Sanitário Família -> Ativo
                31 = Administração
                32 = Espaço Familia
                33 = Espaço Cliente
                34 = Entrada/Portaria -> Ativo
                35 = QR Code
                36 = Bicicletário
                37 = Loteria
                38 = Massagem
                39 = Mobilidade
                40 = Estacionamento de Moto
                41 = Manicure
                42 = Pet Friendily
                43 = Impressão Rápida
                44 = SAC
                45 = Costura
                46 = Relojoaria
              */

              // Definições de cores, seleção e etc... para todas as lojas que podem ser selecionadas
              case 1:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o espaço esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o espaço como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do espaço
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos espaços
                break;

              // Definições de cores, seleção e etc... para todas os Diretórios que podem ser selecionadas
              case 2:
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID os Diretórios
                this.currentMap.children[i].material = defaultMaterial; // Aplicando um material nos Diretórios
                this.currentMap.children[i].currentHex =
                  this.currentMap.children[i].material.color.getHex(); // Salvando a cor default
                break;

              // Definições de cores para todas as escadas
              case 3:
                this.currentMap.children[i].userData.escalator = true; // Atribuindo atributo como escada
                this.currentMap.children[i].userData.locked = true;
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do espaço
                this.currentMap.children[i].currentHex =
                  this.currentMap.children[i].material.color.getHex(); // Salvando a cor atual
                this.currentMap.children[i].default = new THREE.Color(
                  this.$store.state.currentFloor.inactiveSpaceColorWeb
                ); // Salvando a cor default
                this.currentMap.children[i].material = defaultMaterial; // Aplicando material nas escadas
                break;

              // Definições de cores, seleção e etc... para todas os elevadores
              case 5:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o elevador esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o elevador como selecionavel
                this.currentMap.children[i].userData.elevator = true; // Atribuindo atributo como elevador
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do espaço
                this.currentMap.children[i].currentHex =
                  this.currentMap.children[i].material.color.getHex(); // Salvando a cor atual
                this.currentMap.children[i].default = new THREE.Color(
                  this.$store.state.currentFloor.activeSpaceColorWeb
                ); // Salvando a cor default
                this.currentMap.children[i].material = spaceMaterial; // Aplicando material nos elevadores
                break;

              // Definições de cores, seleção e etc... para todos os sanitários
              case 7:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que os sanitários esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o sanitários como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do espaço
                this.currentMap.children[i].currentHex =
                  this.currentMap.children[i].material.color.getHex(); // Salvando a cor default
                this.currentMap.children[i].material = spaceMaterial; // Aplicando material nos sanitários
                break;

              // Definições de cores, seleção e etc... para todos os cinemas
              case 9:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o cinemas esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o cinemas como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do cinemas
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos cinemas
                break;

              // Definições de cores, seleção e etc... para todos os Teatros
              case 10:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Teatros esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Teatros como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Teatros
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Teatros
                break;

              // Definições de cores, seleção e etc... para todos os Fraldários
              case 11:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Fraldários esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Fraldários como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Fraldários
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Fraldários
                break;

              // Definições de cores, seleção e etc... para todos os Caixas eletrônicos
              case 12:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Caixas eletrônicos esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Caixas eletrônicos como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Caixas eletrônicos
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Caixas eletrônicos
                break;

              // Definições de cores, seleção e etc... para todos os Ambulatórios
              case 13:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Ambulatórios esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Ambulatórios como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Ambulatórios
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Ambulatórios
                break;

              // Definições de cores, seleção e etc... para todos os Achados e Perdidos
              case 14:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Achados e Perdidos esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Achados e Perdidos como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Achados e Perdidos
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Achados e Perdidos
                break;

              // Definições de cores, seleção e etc... para todos os Sanitário acessível
              case 25:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Sanitário acessível esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Sanitário acessível como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Sanitário acessível
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Sanitário acessível
                break;

              // Definições de cores, seleção e etc... para todos os Sanitário Masculino
              case 28:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Sanitário Masculino esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Sanitário Masculino como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Sanitário Masculino
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Sanitário Masculino
                break;

              // Definições de cores, seleção e etc... para todos os Sanitário Feminino
              case 29:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Sanitário Feminino esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Sanitário Feminino como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Sanitário Feminino
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Sanitário Feminino
                break;

              // Definições de cores, seleção e etc... para todos os Sanitário Família
              case 30:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Sanitário Família esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Sanitário Família como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Sanitário Família
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Sanitário Família
                break;

              // Definições de cores, seleção e etc... para todos os Entrada/Portaria
              case 34:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Entrada/Portaria esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Entrada/Portaria como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Entrada/Portaria
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Entrada/Portaria
                break;

              // Definições de cores, seleção e etc... para todos os QR Codes
              case 35:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o QR Code esta para o raycaster não funcionar
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do QR Code
                if (
                  this.$store.state.originSpace &&
                  this.$store.state.originSpace.spaceId === objectSpace.spaceId
                ) {
                  this.currentMap.children[i].visible = true;
                } else {
                  this.currentMap.children[i].visible = false;
                }
                break;

              // Definições de cores, seleção e etc... para todos os Bicicletário
              case 36:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Bicicletário esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Bicicletário como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Bicicletário
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Bicicletário
                break;

              // Definições de cores, seleção e etc... para todos os Loteria
              case 37:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Loteria esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Loteria como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Loteria
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Loteria
                break;

              // Definições de cores, seleção e etc... para todos os Massagem
              case 38:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Massagem esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Massagem como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Massagem
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Massagem
                break;

              // Definições de cores, seleção e etc... para todos os Mobilidade
              case 39:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Mobilidade esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Mobilidade como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Mobilidade
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Mobilidade
                break;

              // Definições de cores, seleção e etc... para todos os Estacionamento de Moto
              case 40:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Estacionamento de Moto esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Estacionamento de Moto como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Estacionamento de Moto
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Estacionamento de Moto
                break;

              // Definições de cores, seleção e etc... para todos os Manicure
              case 41:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Manicure esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Manicure como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Manicure
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Manicure
                break;

              // Definições de cores, seleção e etc... para todos os Pet Friendily
              case 42:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Pet Friendily esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Pet Friendily como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Pet Friendily
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Pet Friendily
                break;

              // Definições de cores, seleção e etc... para todos os Impressão Rápida
              case 43:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Impressão Rápida esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Impressão Rápida como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Impressão Rápida
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Impressão Rápida
                break;

              // Definições de cores, seleção e etc... para todos os SAC
              case 44:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o SAC esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o SAC como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do SAC
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos SAC
                break;

              // Definições de cores, seleção e etc... para todos os Costura
              case 45:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Costura esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Costura como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Costura
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Costura
                break;

              // Definições de cores, seleção e etc... para todos os Relojoaria
              case 46:
                this.currentMap.children[i].layers.set(1); // Definindo a layer que o Relojoaria esta para o raycaster funcionar
                this.currentMap.children[i].userData.isSelectable = true; // Definindo o Relojoaria como selecionavel
                this.currentMap.children[i].userData.spaceId =
                  objectSpace.spaceId; // Salvando o ID do Relojoaria
                this.currentMap.children[i].material = spaceMaterial; // Aplicando um material nos Relojoaria
                break;

              // Definições de cor para objetos que não podem ser selecionados
              default:
                this.currentMap.children[i].material = defaultMaterial;
            }
          }
          // Caso o objeto 3D no mapa não esteja na lista recebida pela API
          else {
            // Se ele não estiver travado (para não aplicar nenhuma textura)
            if (!this.currentMap.children[i].userData.locked) {
              // Definições de cor para objetos que não podem ser selecionados
              this.currentMap.children[i].material = defaultMaterial;
            }
          }
          // Se o index atual + 1 for igual a quantidade de objetos 3D do mapa atual, termino a promise
          if (i + 1 === this.currentMap.children.length) {
            resolve();
          }
        }
      });
    },
    async getMapSize() {
      const mapBox = new THREE.Box3().setFromObject(this.currentMap); // Crio uma caixa virtual com o mapa atual
      const mapCenter = mapBox.getCenter(new THREE.Vector3()); // Pego o Centro do mapa
      const mapSize = mapBox.getSize(new THREE.Vector3()); // Pego o tamanho do mapa

      this.minPan = new THREE.Vector3(-mapSize.x / 2, -50, -mapSize.z / 2);
      this.maxPan = new THREE.Vector3(
        mapSize.x / 2,
        mapSize.x / 2,
        mapSize.z / 2
      );

      this.control.minDistance = mapSize.y + 20; // Maximo de zoom-in no mapa
      this.control.maxDistance = mapSize.x + 200; // Maximo de zoom-out no mapa

      this.control.target = mapCenter;
      this.mapCamera.lookAt(mapCenter); // Câmera vai filmar o centro do objeto 3D de origem
      this.mapCamera.position.copy(mapCenter);
      this.mapCamera.position.x +=
        this.$store.state.currentFloor.cameraPositionXWeb;
      this.mapCamera.position.y +=
        this.$store.state.currentFloor.cameraPositionYWeb;
      this.mapCamera.position.z +=
        this.$store.state.currentFloor.cameraPositionZWeb;

      if (this.$vuetify.breakpoint.mobile) {
        this.mapCamera.position.y = mapSize.y + 500; // Definindo a posição da câmera
        this.mapCamera.position.z = mapSize.z + 250; // Definindo a posição de inclinação da câmera
      } else {
        this.mapCamera.position.y = mapSize.y + 100; // Definindo a posição da câmera
        this.mapCamera.position.z = mapSize.z; // Definindo a posição de inclinação da câmera
      }
      this.control.saveState(); // Salvando a visão inicial

      //const gridHelper = new THREE.GridHelper(126, 126);
      //gridHelper.rotateX(Math.PI / -2);
      //this.mapScene.add(gridHelper);
    },
    loadPersonage() {
      // Adicionando pessoa no mapa
      if (
        this.$store.state.mallData.webMapShowPersonage ||
        !this.$vuetify.breakpoint.mobile
      ) {
        const gltLoader = new GLTFLoader(); // Criando o loader de arquivos GLTF
        const dracoLoader = new DRACOLoader(); // Criando o compressor de geometrias
        dracoLoader.setPath("/examples/jsm/libs/draco/");
        gltLoader.setDRACOLoader(dracoLoader);
        gltLoader.load("/assets/person.glb", (gltf) => {
          const person = gltf.scene;
          person.castShadow = true;
          person.receiveShadow = true;
          person.name = "Person";
          this.personToWalk = SkeletonUtils.clone(person);
          this.animationPerson = new THREE.AnimationMixer(this.personToWalk);
          this.animationPerson.clipAction(gltf.animations[0]).play();
          this.personToWalk.visible = false;
          this.personToWalk.scale = new THREE.Vector3(0.8, 0.8, 0.8);
          this.mapScene.add(this.personToWalk);
        });
      } else {
        const circle = new THREE.SphereGeometry(0.5, 10, 10);
        const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        const mesh = new THREE.Mesh(circle, material);
        this.personToWalk = mesh;
        this.personToWalk.visible = false;
        this.mapScene.add(this.personToWalk);
      }
    },

    /**
     *
     * Funções de seleção origem e destino
     *
     *  @function desktopSelection Função executada pelo @function onHover para indicar o espaço no qual o mouse passou por cima
     *  @function onSelect Salvar como origem ou destino a loja que foi efetuado o click do mouse
     *  @function mobileSelection Salvar como origem ou destino a loja que foi efetaudo o toque na tela do celular
     */

    desktopSelection(found) {
      // Se existir um valor e o mesmo for maior que 0, e for um espaço selecionavel
      if (found.length > 0 && found[0].object.userData.isSelectable) {
        // Caso o espaço que foi selecionado seja diferente do novo espaço selecionado
        if (this.selectedSpace != found[0].object) {
          // Se já existir um valor salvo no espaço antigo
          if (this.selectedSpace) {
            // Se o valor o ultimo espaço selecionado for diferente da origem e destino (temporaria)
            if (
              this.selectedSpace !== this.destinationSpace &&
              this.selectedSpace !== this.originSpace
            ) {
              this.selectedSpace.material.color.setHex(
                this.selectedSpace.currentHex
              ); // Aplico a cor default do espaço
              this.selectedSpace.translateZ(-0.2); // Baixo o espaço para altura padrao
            }
          }

          this.selectedSpace = found[0].object; // Salvo o espaço selecionado
          // Se o valor do espaço selecionado for diferente da origem e destino (temporaria)
          if (
            this.selectedSpace !== this.destinationSpace &&
            this.selectedSpace !== this.originSpace
          ) {
            this.selectedSpace.currentHex =
              this.selectedSpace.material.color.getHex(); // Salvo a cor padrão do espaço
            this.selectedSpace.material.color = new THREE.Color(
              this.$store.state.mallData.webHoverSpaceColor
            ); // Aplico a nova cor para o espaço selecionado
            this.selectedSpace.translateZ(0.2); // Subo o espaço acima da altura padrão
          }
        }
      }
      // Caso o espaço que foi selecinado seja igual do novo espaço selecionado
      else {
        // Se já existir um valor salvo no ultimo espaço selecionado
        if (this.selectedSpace) {
          // Se esse espaço selecionado foi diferente da origem e destino
          if (
            this.selectedSpace !== this.destinationSpace &&
            this.selectedSpace !== this.originSpace
          ) {
            this.selectedSpace.material.color.setHex(
              this.selectedSpace.currentHex
            ); // Aplico cor default do espaço
            this.selectedSpace.translateZ(-0.2); // Baixo o espaço para altura padrão
          }
        }
        this.selectedSpace = null; // Removo o espaço selecionado
      }
    },
    onSelect(event) {
      event.preventDefault();
      /*
      const box3 = new THREE.Box3().setFromObject(this.selectedSpace);
      const box3Helper = new THREE.Box3Helper(box3);
      //box3Helper.applyMatrix4(this.selectedSpace.matrixWorld);
      this.mapScene.add(box3Helper);
      */

      // Caso não possua um valor de origem fixa determinado, no clique da loja no mapa, vai setar como origem
      if (this.$store.state.originSpace === null) {
        // Se o destino temporario for diferente do espaço selecionado
        if (this.destinationSpace !== this.selectedSpace) {
          let setOrigin = null;
          this.$store.state.currentFloor.floorObjects.find((object) => {
            if (object.objectName === this.selectedSpace.name) {
              setOrigin = object;
            }
          }); // No andar atual estou procurando o objeto 3D com o mesmo nome do objeto 3D da loja selecionada
          this.$store.commit("setOrigin", setOrigin); // Então salvando como origem inicial fixa
          this.$emit("applyOriginSpaceName", setOrigin); // Então enviado nome da loja selecionada para a lista
          this.selectedSpace.material.color.setHex(
            this.selectedSpace.currentHex
          ); // Estou voltando a cor padrão da loja
          this.selectedSpace.translateZ(-0.2); // Tirando a elevação da loja
        }
      }
      // Caso já possua um valor de origem fixa determinado, no clique da loja no mapa, vai setar como destino
      else {
        // Se a origem temporária for diferente do espaço selecionado
        if (this.originSpace !== this.selectedSpace) {
          // Se já existir um destino salvo, antes da seleção de um novo destino
          if (this.destinationSpace !== null) {
            // Se o ultimo destino selecionado for diferente do novo espaço selecionado
            if (this.destinationSpace !== this.selectedSpace) {
              this.destinationSpace.material.color.setHex(
                this.destinationSpace.currentHex
              ); // Aplico cor default do ultimo destino selecionado
              this.destinationSpace.translateZ(-0.2); // Baixo o ultimo destino para altura padrão
              this.selectedSpace.material.color.setHex(
                this.selectedSpace.currentHex
              ); // Estou voltando a cor padrão da loja selecinada
              this.selectedSpace.translateZ(-0.2); // Tirando a elevação da loja
              this.destinationSpace = null; // Apagando a variavel do ultimo destino selecionado
              let setDestination = null;
              this.$store.state.currentFloor.floorObjects.find((object) => {
                if (object.objectName === this.selectedSpace.name) {
                  setDestination = object;
                }
              }); // No andar atual estou procurando o objeto 3D com o mesmo nome do objeto 3D da loja selecionada
              this.$store.commit("setDestination", setDestination); // Então salvando como destino inicial fixo
              this.$emit("applyDestinationSpaceName", setDestination); // Então enviado nome da loja selecionada para a lista
            }
          } else if (
            /*
              Essa regra abaixo ira funcionar APENAS quando não estivermos utilizando a função para traçar rota,
              dai então o usuário no toque do espaço, estara alternando apenas entre os espaços de origem

              Se já existir um destino temporário salvo, antes da seleção de um novo destino
              Se a origem fixa já estiver determinada
              Se a caixa de traçar a rota estiver desativada
              Se a origem temporária for diferente do espaço selecionado
            */
            this.$store.state.originSpace !== null &&
            !this.$store.state.codeMall.box &&
            this.originSpace !== this.selectedSpace
          ) {
            let setOrigin = null;
            this.$store.state.currentFloor.floorObjects.find((object) => {
              if (object.objectName === this.selectedSpace.name) {
                setOrigin = object;
              }
            }); // No andar atual estou procurando o objeto 3D com o mesmo nome do objeto 3D da loja selecionada
            this.$store.commit("setOrigin", setOrigin); // Então salvando como origem inicial fixa
            this.$emit("applyOriginSpaceName", setOrigin); // Então enviado nome da loja selecionada para a lista
            this.selectedSpace.material.color.setHex(
              this.selectedSpace.currentHex
            ); // Estou voltando a cor padrão da loja
            this.selectedSpace.translateZ(-0.2); // Tirando a elevação da loja
          }
          // Caso já exista um destino temporário salvo
          else {
            // Se a origem temporária for diferente do espaço selecinado e a caixa para traçar a rota estiver habilitada
            if (
              this.originSpace !== this.selectedSpace &&
              this.$store.state.codeMall.box
            ) {
              this.selectedSpace.material.color.setHex(
                this.selectedSpace.currentHex
              ); // Estou voltando a cor padrão da loja
              this.selectedSpace.translateZ(-0.2); // Tirando a elevação da loja
              let setDestination = null;
              this.$store.state.currentFloor.floorObjects.find((object) => {
                if (object.objectName === this.selectedSpace.name) {
                  setDestination = object;
                }
              }); // No andar atual estou procurando o objeto 3D com o mesmo nome do objeto 3D da loja selecionada
              this.$store.commit("setDestination", setDestination); // Então salvando como destino inicial fixo
              this.$emit("applyDestinationSpaceName", setDestination); // Então enviado nome da loja selecionada para a lista
            }
          }
        }
      }
    },
    mobileSelection() {
      this.raycaster.layers.set(1); // Determinando quais espaços podem ser selecionados
      if (this.mouse !== null) {
        this.raycaster.setFromCamera(this.mouse, this.setCameraView());
        const found = this.raycaster.intersectObjects(
          this.mapScene.children,
          true
        ); // Determinando qual câmera o raycaster vai utilizar para busca

        // Caso o locol onde foi tocado seja valido e seja um espaço selecinavel
        if (found.length > 0 && found[0].object.userData.isSelectable) {
          // Caso não possua um valor de origem determinado, no clique da loja no mapa, vai setar como origem
          if (this.$store.state.originSpace === null) {
            // Se a origem temporária já tiver um valor salvo e o novo espaço selecionado seja diferente do destino temporário
            if (
              this.originSpace !== null &&
              found[0].object !== this.destinationSpace
            ) {
              this.originSpace.material.color.setHex(
                this.originSpace.currentHex
              ); // Aplico a cor default
              this.originSpace.translateZ(-0.2); // Baixo o elemento 3D
              this.originSpace = found[0].object; // Salvo a origem
            }
            // Caso não exita uma origem temporária salva e o novo espaço selecinado seja diferente do destino temporário
            else if (found[0].object !== this.destinationSpace) {
              let setOrigin = null;
              this.$store.state.currentFloor.floorObjects.find((object) => {
                if (object.objectName === found[0].object.name) {
                  setOrigin = object;
                }
              }); // No andar atual estou procurando o objeto 3D com o mesmo nome do objeto 3D da loja selecionada
              this.$store.commit("setOrigin", setOrigin); // Então salvando como origem inicial fixa
              this.$emit("applyOriginSpaceName", setOrigin); // Então enviado nome da loja selecionada para a lista
              // Procuro no mapa atual o objeto 3D selecionado
              this.$store.state.currentFloor.floorObjects.find((object) => {
                if (object.objectName === found[0].object.name) {
                  this.selectedSpaceName = object.space.name;
                }
              });
              this.$emit("updateSpaceName", this.selectedSpaceName); // Envio o nome do espaço para aparecer na tela
            }
          } else if (
            this.$store.state.originSpace !== null &&
            !this.$store.state.codeMall.box &&
            this.originSpace !== found[0].object
          ) {
            this.originSpace.material.color.setHex(this.originSpace.currentHex); // Aplico a cor default
            this.originSpace.translateZ(-0.2); // Baixo o elemento 3D
            this.originSpace = found[0].object; // Salvo a origem
            // Procuro no mapa atual o objeto 3D selecionado
            this.$store.state.currentFloor.floorObjects.find((object) => {
              if (object.objectName === found[0].object.name) {
                this.selectedSpaceName = object.space.name;
              }
            });
            this.$emit("updateSpaceName", this.selectedSpaceName); // Envio o nome do espaço para aparecer na tela
          }
          // Caso a origem já possua um valor a cada toque sera trocado o destino
          else {
            // Caso a origem seja diferente do espaço selecionado
            if (
              this.originSpace !== found[0].object &&
              this.$store.state.codeMall.box
            ) {
              // Caso já possua algum valor salva como destino
              if (this.destinationSpace !== null) {
                // Caso o destino atual seja diferente do espaço selecionado
                if (this.destinationSpace !== found[0].object) {
                  this.destinationSpace.material.color.setHex(
                    this.destinationSpace.currentHex
                  ); // Estou voltando a cor padrão da loja
                  this.destinationSpace.translateZ(-0.2); // Tirando a elevação da loja
                  this.destinationSpace = null;
                  let setDestination = null;
                  this.$store.state.currentFloor.floorObjects.find((object) => {
                    if (object.objectName === found[0].object.name) {
                      setDestination = object;
                    }
                  }); // No andar atual estou procurando o objeto 3D com o mesmo nome do objeto 3D da loja selecionada
                  this.$store.commit("setDestination", setDestination); // Então salvando como destino inicial fixo
                  this.$emit("applyDestinationSpaceName", setDestination); // Então enviado nome da loja selecionada para a lista
                }
              }
              // Caso não possua um valor de destino temporário salvo
              else {
                let setDestination = null;
                this.$store.state.currentFloor.floorObjects.find((object) => {
                  if (object.objectName === found[0].object.name) {
                    setDestination = object;
                  }
                }); // No andar atual estou procurando o objeto 3D com o mesmo nome do objeto 3D da loja selecionada
                this.$store.commit("setDestination", setDestination); // Então salvando como destino inicial fixo
                this.$emit("applyDestinationSpaceName", setDestination); // Então enviado nome da loja selecionada para a lista
              }
            }
            // Procuro no mapa atual o objeto 3D com o mesmo nome do espaço selecionado
            this.$store.state.currentFloor.floorObjects.find((object) => {
              if (object.objectName === found[0].object.name) {
                this.selectedSpaceName = object.space.name;
              }
            });
            this.$emit("updateSpaceName", this.selectedSpaceName); // Envio o nome do espaço para aparecer na tela
          }
        }
      }
    },

    /**
     *
     * Funções de indicar tipo de rota
     *
     *  @function loadOriginSpaceMap 1º Função que será executada sempre que o botão "Traçar Rota" é ativado
     *  @function traceRoute 2º Função que será executada, para identificar a diferença entre o andar de origem e destino
     *  @function sameFloorRoute 3º Função que será executada, caso a rota seja para lojas no mesmo andar
     *  @function diferentFloorRoute 3º Função que será executada, caso a rota seja de lojas com andares diferente
     *  @function showNextFloorPatch 4º Função que será executada sempre que o botão "Continuar" é ativado
     *
     */

    loadOriginSpaceMap() {
      this.selectedSpaceName = null; // Apago o nome da loja selecionada
      this.$emit("updateSpaceName", this.selectedSpaceName); // Envio o nome como null

      this.removeEventsListeners();

      // Caso o andar atual seja diferente do andar de origem
      if (
        this.$store.state.currentFloor.level !==
        this.$store.state.originSpace.floor
      ) {
        this.$emit("changeFloor", this.$store.state.originSpace.floor); // Envio comando para trocar o andar
        this.$store.state.inRoute = true; // Defino que o usuário esta em rota, para que a rota seja iniciada automaticamente
      }
      // Caso o andar atual seja igual ao andar de origem
      else {
        this.$store.state.inRoute = true; // Defino que o usuário esta em rota, para que a rota seja iniciada automaticamente
        this.traceRoute(); // Executo a função para traçar a rota
      }
    },
    traceRoute() {
      this.setPictogramStatus(false); // Estou deixando os pictogramas do mapa invisiveis
      // Caso o andar atual que a pessoa esta vendo for diferente do andar de destino
      if (
        this.$store.state.currentFloor.level !==
        this.$store.state.destinationSpace.floor
      ) {
        this.diferentFloorRoute(); // Função para rota em andares diferente
      } else {
        this.sameFloorRoute(); // Função para rota no mesmo mapa
      }
    },
    sameFloorRoute() {
      /*
        Procuro no mapa atual o ultimo this.originSpace salvo, caso não ache significa que:
        A rota definida pelo usuario está vindo de um andar diferente, por conta disso.
        Vai estar salvo na variavel this.originSpace, o objeto 3D do ultimo espaço ou plataforma do andar anterior
      */
      if (
        this.currentMap.getObjectByName(this.originSpace.name) === undefined
      ) {
        // Procuro a plataforma do andar atual que foi utilizada como acesso do andar anterior
        this.currentMap.children.find((object) => {
          // Caso eu encontre a plataforma salvo ela como origem
          if (
            object.userData.spaceId === this.destinationSpace.userData.spaceId
          ) {
            this.originSpace = object;
            return true;
          }
        });
      }

      /* 
        Procuro no mapa atual o ultimo this.destinationSpace salvo, caso não ache significa que:
        A rota definida pelo usuario esta vindo de um um andar diferente, por conta disso.
        Vai estar salvo na variavel this.destinationSpace, o objeto 3D da ultima plataforma do andar anterior
      */
      if (
        this.currentMap.getObjectByName(this.destinationSpace.name) ===
        undefined
      ) {
        // Procuro no mapa atual o destino final escolhido pelo usuario
        this.destinationSpace = this.currentMap.getObjectByName(
          this.$store.state.destinationSpace.objectName
        );
      }

      // Salvo posição XYZ da loja de origem
      const originBoxCenter = this.findMidPointOfSpace(this.originSpace);

      // Salvo posição XYZ da loja de destino
      const destinationBoxCenter = this.findMidPointOfSpace(
        this.destinationSpace
      );

      this.personToWalk.position.copy(originBoxCenter); // Determino a posição da "Bola" igual a loja de origem

      const agentPosition = this.personToWalk.position; // Salvo posição do cubo como um agente

      // Procuro um caminho do local de origem e destino
      const path = this.findPath(
        originBoxCenter,
        destinationBoxCenter,
        agentPosition
      );

      // Caso ele encontre um caminho inicio a rota normalmente
      if (path !== false) {
        this.navpath = path;
        this.addObjectPathOnScene();
        this.oldExclusiveAccess = this.exclusiveAccess;
        this.exclusiveAccess = null;
      }
      // Caso ele não encontre uma rota, procuro um acesso exclusivo para loja destino
      else {
        if (this.routeUsingElevator) {
          this.exclusiveRoute = true;
          const newPath = this.originToClosestElevator(originBoxCenter); // Função para procurar elevador mais proxima do ponto de origem
          if (newPath) {
            this.navpath = newPath;
            this.addObjectPathOnScene();
          }
        } else {
          const newPath = this.originToClosestEscalator(originBoxCenter); // Função para procurar escada mais proxima do ponto de origem
          if (newPath) {
            this.navpath = newPath;
            this.addObjectPathOnScene();
          }
        }
      }
    },
    diferentFloorRoute() {
      /*
          Toda vez que a função é executada verificamos se o andar atual esta no mesmo andar do espaço de origem
          Caso seja a primeira vez que a função seja executada esse valor será verdadeiro, sendo assim definimos como origem
          o espaço selecionado.
          Da segunda vez em diante o valor será falso, sendo assim a origem se torna a ultima plataforma utilizada do andar
          anterior.
        */
      if (
        this.$store.state.currentFloor.level ===
        this.$store.state.originSpace.floor
      ) {
        if (!this.exclusiveRoute) {
          // Procuro no mapa atual o objeto 3D da loja de origem inicial selecionada pelo usuario
          this.originSpace = this.currentMap.getObjectByName(
            this.$store.state.originSpace.objectName
          );
        } else {
          // Procuro a plataforma do andar atual que foi utilizada como acesso do andar anterior
          this.currentMap.children.find((object) => {
            if (
              object.userData.spaceId === this.destinationSpace.userData.spaceId
            ) {
              this.originSpace = object;
              this.exclusiveRoute = false;
            }
          });
        }
      }
      // Caso o andar atual seja diferente do andar da loja de origem
      else {
        // Procuro a plataforma do andar atual que foi utilizada como acesso do andar anterior
        this.currentMap.children.find((object) => {
          if (
            object.userData.spaceId === this.destinationSpace.userData.spaceId
          ) {
            this.originSpace = object;
          }
        });
      }

      // Salvo posição XYZ da origem
      const originBox = this.findMidPointOfSpace(this.originSpace);
      let changePath = null;

      let destination = null;
      // Se a rota estiver definida para utilizar elevador e o andar do elevador não estiver nulo
      if (
        this.routeUsingElevator &&
        this.exclusiveAccess.elevatorLevel !== null
      ) {
        // Procuro no mapa atual o objeto 3D com mesmo nome do acesso exclusivo
        this.$store.state.currentFloor.floorObjects.find((elevator) => {
          if (elevator.spaceId === this.exclusiveAccess.elevator.spaceId) {
            this.destinationSpace = this.currentMap.getObjectByName(
              elevator.objectName
            );
          }
        });
      }
      // Se a rota estiver definida para utilizar escada
      else {
        // Se existir um acesso exclusivo a escada
        if (this.exclusiveAccess.escalatorLevel !== null) {
          // Se o andar atual for igual ao acesso exclusivo a escada
          if (
            this.$store.state.currentFloor.level ===
            this.exclusiveAccess.escalatorLevel
          ) {
            const midPoint = this.findMidPointOfSpace(this.originSpace);
            this.calculateStairsPath(midPoint, "origin");
            const newDestination = this.closestPathBetweenOriginDestination();

            if (
              this.exclusiveAccess.escalator.objectName ===
              newDestination.objectName
            ) {
              // Procuro no mapa atual a escada com o mesmo spaceId da escada exclusiva e salvo como destino temporário
              this.$store.state.currentFloor.floorObjects.find((escalator) => {
                if (
                  escalator.spaceId === this.exclusiveAccess.escalator.spaceId
                ) {
                  this.destinationSpace = this.currentMap.getObjectByName(
                    escalator.objectName
                  );
                }
              });
            } else {
              this.destinationSpace = this.currentMap.getObjectByName(
                newDestination.objectName
              );
            }
          }
          // Se o andar atual for diferente ao acesso exclusivo a escada
          else {
            // Faço uma comparação para verificar se o andar atual e o andar da escada exclusiva, possuem alguma interligação
            const findEscalator = this.floorsConnections.some((array) =>
              this.checkFloorConnections(
                array,
                { level: this.$store.state.currentFloor.level },
                { level: this.exclusiveAccess.escalatorLevel }
              )
            );
            // Caso os andares possuam uma interligação por escadas
            if (findEscalator) {
              const newPath = this.originToClosestEscalator(originBox); // Função para procurar escada mais proxima do ponto de origem

              if (newPath) {
                changePath = newPath;
              }
            }
            // Caso não possua uma interligação de escadas a rota será de elevador
            else {
              // Se existir um elevador exclusivo salvo
              if (this.exclusiveAccess.elevatorLevel !== null) {
                this.routeUsingElevator = true;
                // Procuro no mapa atual o objeto 3D com mesmo nome do acesso exclusivo
                this.$store.state.currentFloor.floorObjects.find((elevator) => {
                  if (
                    elevator.spaceId === this.exclusiveAccess.elevator.spaceId
                  ) {
                    this.destinationSpace = this.currentMap.getObjectByName(
                      elevator.objectName
                    );
                  }
                });
              }
              // Caso não exista um elevador exclusivo a rota não é possivel ser traçada
              else {
                this.navpath = [];
                this.resetAllRouteValues();
              }
            }
          }
        } else {
          this.routeUsingElevator = true;
          // Procuro no mapa atual o objeto 3D com mesmo nome do acesso exclusivo
          this.$store.state.currentFloor.floorObjects.find((elevator) => {
            if (elevator.spaceId === this.exclusiveAccess.elevator.spaceId) {
              this.destinationSpace = this.currentMap.getObjectByName(
                elevator.objectName
              );
            }
          });
        }
      }
      // Salvo posição XYZ do destino
      destination = this.findMidPointOfSpace(this.destinationSpace);

      this.personToWalk.position.copy(originBox); // Copio a posição da origem para a "bola"

      const agentPosition = this.personToWalk.position; // Salvo posição do cubo

      // Função para encontrar um caminho do ponto de origem até o ponto de destino
      const path = changePath
        ? changePath
        : this.findPath(originBox, destination, agentPosition);

      // Caso encontre um caminho adiciono ele na tela e inicio a rota
      if (path !== false) {
        this.navpath = path;
        this.addObjectPathOnScene();
      } else {
        if (this.routeUsingElevator) {
          this.exclusiveRoute = true;
          this.newRouteToNextFloorToElevator = true;
          this.originToClosestElevator(originBox); // Função para procurar elevador mais proxima do ponto de origem
        } else {
          this.originToClosestEscalator(originBox); // Função para procurar escada mais proxima do ponto de origem
        }
      }
    },
    showNextFloorPatch() {
      // Se a rota estiver definida como utilizar elevadores
      if (this.routeUsingElevator) {
        // Se a rota estiver definida para subir um andar depois utilzar o elevador
        if (this.newRouteToNextFloorToElevator || this.exclusiveRoute) {
          if (
            this.$store.state.currentFloor.level >
            this.$store.state.destinationSpace.floor
          ) {
            this.personToWalk.visible = false;
            this.newRouteToNextFloorToElevator = false;
            this.exclusiveRoute = false;
            this.$store.state.mallData.floors.find((level) => {
              if (level.level === this.$store.state.destinationSpace.floor) {
                level.floorObjects.find((space) => {
                  if (space.spaceId === this.exclusiveAccess.elevator.spaceId) {
                    this.$emit(
                      "changeFloor",
                      this.$store.state.currentFloor.level - 1
                    ); // Envio comando para trocar o botão do andar
                  } else {
                    const floors = this.$store.state.mallData.floors.filter(
                      (level) =>
                        level.level !==
                          this.$store.state.destinationSpace.floor &&
                        level.level !== this.$store.state.currentFloor.level
                    );
                    floors.find((object) => {
                      const findElevator = object.floorObjects.find(
                        (space) =>
                          space.spaceId ===
                          this.destinationSpace.userData.spaceId
                      );
                      if (findElevator) {
                        if (
                          object.level > this.$store.state.currentFloor.level
                        ) {
                          this.exclusiveRoute = true;
                          this.$emit("changeFloor", object.level); // Envio comando para trocar o botão do andar
                        } else {
                          this.$emit(
                            "changeFloor",
                            this.$store.state.destinationSpace.floor
                          ); // Envio comando para trocar o botão do andar
                        }
                        return true;
                      }
                    });
                  }
                });
              }
            });
          } else {
            this.personToWalk.visible = false;
            this.$emit("changeFloor", this.exclusiveAccess.elevatorLevel); // Envio comando para trocar o botão do andar
            this.newRouteToNextFloorToElevator = false;
            this.exclusiveRoute = false;
          }
        } else {
          // Carrego o ultimo andar direto
          this.$emit("changeFloor", this.$store.state.destinationSpace.floor);
        }
      } else {
        if (
          this.$store.state.currentFloor.level >
          this.$store.state.destinationSpace.floor
        ) {
          this.personToWalk.visible = false;
          this.$store.state.mallData.floors.find((level) => {
            if (level.level === this.$store.state.destinationSpace.floor) {
              level.floorObjects.find((space) => {
                if (space.spaceId === this.exclusiveAccess.escalator.spaceId) {
                  this.$emit(
                    "changeFloor",
                    this.$store.state.currentFloor.level - 1
                  ); // Envio comando para trocar o botão do andar
                  return true;
                } else {
                  const floors = this.$store.state.mallData.floors.filter(
                    (level) =>
                      level.level !==
                        this.$store.state.destinationSpace.floor &&
                      level.level !== this.$store.state.currentFloor.level
                  );
                  floors.find((object) => {
                    const findEscalator = object.floorObjects.find(
                      (space) =>
                        space.spaceId === this.destinationSpace.userData.spaceId
                    );
                    if (findEscalator) {
                      if (object.level > this.$store.state.currentFloor.level) {
                        this.exclusiveRoute = true;
                        this.$emit(
                          "changeFloor",
                          this.$store.state.currentFloor.level + 1
                        ); // Envio comando para trocar o botão do andar
                        return true;
                      } else {
                        this.$emit(
                          "changeFloor",
                          this.$store.state.currentFloor.level - 1
                        ); // Envio comando para trocar o botão do andar
                        return true;
                      }
                    }
                  });
                }
              });
            }
          });
        } else {
          this.personToWalk.visible = false;
          this.$emit("changeFloor", this.$store.state.currentFloor.level + 1); // Envio comando para trocar o botão do andar
          return true;
        }
      }
    },

    /**
     *
     * Funções para construção de rota
     *
     *  @function originToClosestEscalator Função para retornar a escada mais proxima do ponto de origem
     *  @function findClosestEscalator Função que vai calcular a escada mais proxima do ponto de origem
     *  @function originToClosestElevator Função para retornar o elevador mais proximo do ponto de origem
     *  @function findClosestElevator Função que vai calcular o elevador mais proximo do ponto de origem
     *  @function findPath Função que vai calcular a distancia entre os 2 pontos e retornar uma array
     *  @function findMidPointOfSpace Função que vai retornar o ponto XYZ do objeto selecionado
     */

    originToClosestEscalator(originCenter) {
      // Procuro do ponto de origem a escada mais proxima
      const accessToChangeFloor = this.findClosestEscalator(originCenter);
      // Salvo como destino o acesso encontrado
      this.destinationSpace = accessToChangeFloor.access;

      this.personToWalk.position.copy(originCenter); // Determino a posição da "Bola" igual a loja de origem

      const agentPosition = this.personToWalk.position; // Salvo posição do cubo como um agente

      // Procuro um caminho do ponto de origem até o ponto de destino
      const path = this.findPath(
        agentPosition,
        accessToChangeFloor.position,
        agentPosition
      );

      // Caso encontre um caminho adiciono ele na tela e inicio a rota
      if (path) {
        return path;
      } else {
        this.exclusiveRoute = true;
        this.originToClosestElevator(originCenter); // Função para procurar elevador mais proxima do ponto de origem
      }
    },
    findClosestEscalator(origin) {
      const agentPosition = origin; // Salvo a posição da origem
      let destinationBox = []; // Array onde irei armazenar todos os acessos
      let checkLowPath = null; // Vou armazenar o tamanho da menor (length) da array de path encontrada
      let finalDestination = {}; // Vou armazenar a posição XYZ do acesso mais proximo encontrado

      // No mapa atual vou procurar todos os acessos pesquisando pelo tipo de espaço 3 (escadas)
      this.$store.getters.getCurrentFloorStairs.find((escalator) => {
        /*
          Caso o andar de destino seja maior que andar atual eu vou filtrar a lista com todos os andares apenas
          o andar superior
        */
        if (
          this.exclusiveAccess.escalatorLevel >
          this.$store.state.currentFloor.level
        ) {
          const HighFloorAccess = this.$store.getters.getHighFloorStairs;

          // Para caso acesso com o mesmo spaceId do andar atual com o andar superior, vou adicionar na array
          HighFloorAccess.forEach((access) => {
            if (
              access.spaceId === escalator.spaceId &&
              access.spaceId !== this.originSpace.userData.spaceId
            ) {
              destinationBox.push(escalator);
            }
          });
        } else if (
          this.exclusiveAccess.escalatorLevel <
          this.$store.state.currentFloor.level
        ) {
          /*
              Caso o andar de destino seja menor que andar atual eu vou filtrar a lista com todos os andares apenas
              o andar superior
            */
          const lowFloorAccess = this.$store.getters.getLowerFloorStairs;
          // Para caso acesso com o mesmo spaceId do andar atual com o andar inferior, vou adicionar na array
          lowFloorAccess.forEach((access) => {
            if (
              access.spaceId === escalator.spaceId &&
              access.spaceId !== this.originSpace.userData.spaceId
            ) {
              destinationBox.push(escalator);
            }
          });
        } else {
          destinationBox.push(escalator);
        }
      });

      // Para cada acesso encontrado vou procurar no mapa atual
      destinationBox.forEach((checkDistance) => {
        const access = this.currentMap.getObjectByName(
          checkDistance.objectName
        );

        // Vou pegar a posição XYZ de cada acesso
        const box = this.findMidPointOfSpace(access);

        const path = this.findPath(agentPosition, box, agentPosition);

        /*
        const material = new THREE.LineBasicMaterial({
          color: 0x0000ff,
        });
        const geometry = new THREE.BufferGeometry().setFromPoints(path);
        const line = new THREE.Line(geometry, material);
        this.mapScene.add(line);
        */

        // Caso exista algum caminho já encontrado
        if (checkLowPath) {
          if (path !== false) {
            // Verifica se o caminho atual é menor que o ultimo menor caminho encontrado
            if (path.length < checkLowPath.length) {
              checkLowPath = path; // Salva o tamanho da array do caminho
              finalDestination.position = box; // Salva a posição XYZ desse destino
              finalDestination.access = access; // Salvo como destino
              finalDestination.type = 3; // Salvo o tipo de acesso utilizado
            }
          }
        } else {
          checkLowPath = path; // Salva o tamanho da array do caminho
          finalDestination.position = box; // Salva a posição XYZ desse destino
          finalDestination.access = access; // Salvo como destino
          finalDestination.type = 3; // Salvo o tipo de acesso utilizado
        }
      });

      /*
        const material = new THREE.LineBasicMaterial({
          color: 0xff000,
        });
        const finalgeometry = new THREE.BufferGeometry().setFromPoints(
          checkLowPath
        );
        const finalDestinationline = new THREE.Line(finalgeometry, material);
        this.mapScene.add(finalDestinationline);
      */

      return finalDestination;
    },
    originToClosestElevator(originCenter) {
      // Procuro do ponto de origem a escada mais proxima
      const accessToChangeFloor = this.findClosestElevator(originCenter);
      // Salvo como destino o acesso encontrado
      this.destinationSpace = accessToChangeFloor.access;

      this.personToWalk.position.copy(originCenter); // Determino a posição da "Bola" igual a loja de origem

      const agentPosition = this.personToWalk.position; // Salvo posição do cubo como um agente

      // Procuro um caminho do ponto de origem até o ponto de destino
      const path = this.findPath(
        agentPosition,
        accessToChangeFloor.position,
        agentPosition
      );

      // Caso encontre um caminho adiciono ele na tela e inicio a rota
      if (path) {
        this.navpath = path;
        this.addObjectPathOnScene();
      } else {
        this.navpath = [];
        this.resetAllRouteValues();
        return false;
      }
    },
    findClosestElevator(origin) {
      const agentPosition = origin; // Salvo a posição da origem
      let destinationBox = []; // Array onde irei armazenar todos os acessos
      let checkLowPath = null; // Vou armazenar o tamanho da menor (length) da array de path encontrada
      let finalDestination = {}; // Vou armazenar a posição XYZ do acesso mais proximo encontrado

      // No mapa atual vou procurar todos os acessos pesquisando pelo tipo de espaço 5 (elevadores)
      this.$store.state.currentFloor.floorObjects.find((elevator) => {
        if (elevator.space.spaceType === 5) {
          const floor = this.$store.state.mallData.floors.filter(
            (level) => level.level !== this.$store.state.destinationSpace.floor
          );
          floor.forEach((level) => {
            return level.floorObjects.find((object) => {
              if (object.spaceId === elevator.spaceId) {
                if (
                  !destinationBox.some(
                    (space) => space.spaceId === elevator.spaceId
                  )
                ) {
                  destinationBox.push(elevator);
                }
              }
            });
          });
        }
      });

      // Para cada acesso encontrado vou procurar no mapa atual
      destinationBox.forEach((checkDistance) => {
        const access = this.currentMap.getObjectByName(
          checkDistance.objectName
        );

        // Vou pegar a posição XYZ de cada acesso
        const box = this.findMidPointOfSpace(access);

        const path = this.findPath(agentPosition, box, agentPosition);

        /*
        const material = new THREE.LineBasicMaterial({
          color: 0x0000ff,
        });
        const geometry = new THREE.BufferGeometry().setFromPoints(path);
        const line = new THREE.Line(geometry, material);
        this.mapScene.add(line);
        */

        // Caso exista algum caminho já encontrado
        if (checkLowPath) {
          if (path !== false) {
            // Verifica se o caminho atual é menor que o ultimo menor caminho encontrado
            if (path.length < checkLowPath.length) {
              checkLowPath = path; // Salva o tamanho da array do caminho
              finalDestination.position = box; // Salva a posição XYZ desse destino
              finalDestination.access = access; // Salvo como destino
              finalDestination.type = 5; // Salvo o tipo de acesso utilizado
            }
          }
        } else {
          if (path !== false) {
            checkLowPath = path; // Salva o tamanho da array do caminho
            finalDestination.position = box; // Salva a posição XYZ desse destino
            finalDestination.access = access; // Salvo como destino
            finalDestination.type = 5; // Salvo o tipo de acesso utilizado
          }
        }
      });

      /*
      const material = new THREE.LineBasicMaterial({
        color: 0xff000,
      });
      const finalgeometry = new THREE.BufferGeometry().setFromPoints(
        checkLowPath
      );
      const finalDestinationline = new THREE.Line(finalgeometry, material);
      this.mapScene.add(finalDestinationline);
      */
      return finalDestination;
    },
    findPath(origin, destination, personPosition) {
      this.groupID = this.pathfinding.getGroup(this.ZONE, personPosition); // Crio um novo grupo para calcular rota

      // Localizo o ponto mais proximo do local de origem caso eu encontre
      if (
        this.pathfinding.getClosestNode(origin, this.ZONE, this.groupID) !==
        null
      ) {
        const closest = this.pathfinding.getClosestNode(
          origin,
          this.ZONE,
          this.groupID
        );

        // Procuro o caminho do local de origem até o destino
        if (
          this.pathfinding.findPath(
            closest.centroid,
            destination,
            this.ZONE,
            this.groupID
          ) !== null
        ) {
          // Crio uma rota da posição do cubo até o destino
          return this.pathfinding.findPath(
            closest.centroid,
            destination,
            this.ZONE,
            this.groupID
          );
        }
        // Caso não encontre um caminho
        else {
          this.navpath = [];
          return false;
        }
      }
      // Caso eu não encontre
      else {
        this.navpath = [];
        return false;
      }
    },
    findMidPointOfSpace(space) {
      const geometry = space.geometry; // Salvo o objeto 3D a ser analizado
      const matrixTransformation = space.matrixWorld; // Pego posição dele no mapa e salvo a matriz

      const allVerterx = geometry.attributes.position.array; // Armazeno todos os vertices do espaço selecionado

      /*
        Salvo a posição x,y e z do primeiro vertice, com ele vou poder encontrar a distancia do ponto mais longe e
        a distancia do proximo vertice, para calcular qual está exatamente sobre a malha
      */
      const firtVertex = new THREE.Vector3(
        allVerterx[3],
        allVerterx[3 + 1],
        allVerterx[3 + 2]
      );

      const secondVertex = new THREE.Vector3(
        allVerterx[0],
        allVerterx[0 + 1],
        allVerterx[0 + 2]
      );

      // Salvo a posição do vertice em relação ao mapa
      const firstVertexPosition = new THREE.Vector3()
        .copy(firtVertex)
        .applyMatrix4(matrixTransformation);

      const secondVertexPosition = new THREE.Vector3()
        .copy(secondVertex)
        .applyMatrix4(matrixTransformation);

      let currentDistance = null; // Ultima distancia entre pontos encontrado
      let farPoint = null; // Ponto mais longe do firstVertex encontrado

      // Para cada vertice dentro da forma geométrica vou fazer o calculo de distancia entre os vertices
      for (let i = 0; i < allVerterx.length; i += 3) {
        const x = allVerterx[i]; // Salvo a posição x do vertice
        const y = allVerterx[i + 1]; // Salvo a posição y do vertice
        const z = allVerterx[i + 2]; // Salvo a posição z do vertice

        // Salvo a posição do vertice em relação ao mapa
        const currentVertex = new THREE.Vector3(x, y, z).applyMatrix4(
          matrixTransformation
        );

        // Caso não exista um valor da maior distancia encontrada
        if (currentDistance === null) {
          // Salvo a distancia entre o vertice atual e o primeiro vertice
          currentDistance = firstVertexPosition.distanceTo(currentVertex);
        }
        // Caso já exista um valor da maior distancia encontrada
        else {
          // Se a distancia encontrada for menor que a ultima distancia
          if (currentDistance < firstVertexPosition.distanceTo(currentVertex)) {
            // Salvo a distancia entre o vertice atual e o primeiro vertice
            currentDistance = firstVertexPosition.distanceTo(currentVertex);
            // Salvo a posição do vertice mais longe em relação ao primeiro vertice
            farPoint = currentVertex;
          } else {
            farPoint = currentVertex;
          }
        }
      }

      const midPoint = new THREE.Vector3(); // Variavel que vai armazenar a posição x,y e z do centro entre os 2 pontos
      midPoint.addVectors(farPoint, firstVertexPosition); // Adicionando o valor dos 2 vetores para o calculo do centro
      midPoint.divideScalar(2); // Calculando o centro em relação a distancia dos 2 pontos
      midPoint.y += 1;

      const midPoint2 = new THREE.Vector3(); // Variavel que vai armazenar a posição x,y e z do centro entre os 2 pontos
      midPoint2.addVectors(firstVertexPosition, secondVertexPosition); // Adicionando o valor dos 2 vetores para o calculo do centro
      midPoint2.divideScalar(2); // Calculando o centro em relação a distancia dos 2 pontos
      midPoint2.y += 1;

      /*
      space.material.wireframe = true;
      const box = new THREE.BoxGeometry(1, 1, 1);
      const material1 = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      const mesh1 = new THREE.Mesh(box, material1);
      mesh1.position.copy(midPoint);
      this.mapScene.add(mesh1);
      console.log(this.currentMap);
      */

      // Criando um novo Raycaster apenas para analisar a intersecção entre os objetos
      const ray = new THREE.Raycaster();

      // Definindo como parametro a origem e a direção em que o raycaster vai fazer a analise de intersecção
      ray.set(midPoint, new THREE.Vector3(0, -3, 0));

      // Estou definindo em qual objeto irei testar o ponto de intersecção
      const intersect = ray.intersectObject(this.navmesh);

      if (intersect.length > 0) {
        return intersect[0].point;
      } else {
        // Definindo como parametro a origem e a direção em que o raycaster vai fazer a analise de intersecção
        ray.set(midPoint2, new THREE.Vector3(0, -10, 0));
        // Estou definindo em qual objeto irei testar o ponto de intersecção
        const newintersect = ray.intersectObject(this.navmesh);
        if (newintersect.length > 0) {
          return newintersect[0].point;
        } else {
          const box = new THREE.Box3()
            .setFromObject(space)
            .getCenter(new THREE.Vector3());
          box.y = -this.navmesh.position.z;
          return box;
        }
      }
    },

    /**
     *
     * Funções de definições de acessos exclusivos para rota
     *
     *  @function setExclusiveAccess Função que vai armazenar a escada e o elevador mais proximo do destino
     *  @function findRightAcessToElevatorAndEscalator Função que vai encontrar em todos os andares a escada e elevador correto, para acesso exclusivo
     *  @function reloadExclusiveAccess Função para retornar ultimo acesso exclusivo utilizado
     */

    async setExclusiveAccess() {
      this.exclusiveAccess = new Object(); // Determinando que a variavel é um novo objeto

      // Crio uma caixa para pegar o centro do espaço de destino
      const destinationBoxCenter = this.findMidPointOfSpace(
        this.destinationSpace
      );

      // Salvo o resultado da função para encontrar a escada mais proxima do destino
      const exclusiveAccessEscalator =
        this.findClosestEscalator(destinationBoxCenter);
      // Salvo o resultado da função para encontrar o elevador mais proximo do destino
      const exclusiveAccessElevator =
        this.findClosestElevator(destinationBoxCenter);
      await this.findRightAcessToElevatorAndEscalator(
        exclusiveAccessEscalator,
        exclusiveAccessElevator
      );
      setTimeout(() => {
        if (
          this.$store.state.codeMall.origin &&
          this.$store.state.codeMall.destino &&
          !this.$store.state.codeMall.box
        ) {
          this.loadOriginSpaceMap();
        }
      }, 1000);
    },
    async findRightAcessToElevatorAndEscalator(
      exclusiveAccessEscalator,
      exclusiveAccessElevator
    ) {
      return new Promise((resolve) => {
        // Procuro em cada andar a escada e elevador com o mesmo spaceID encontrado na escada e elevador mais proximo do destino
        this.$store.state.mallData.floors.find((object) => {
          // Se o andar atual for diferente do elemento da array
          if (object.level !== this.$store.state.currentFloor.level) {
            // Procuro no andar do elemento da array o acesso ao elevador e escada
            object.floorObjects.find((access) => {
              // Se o objeto já possui algum parametro ( andar e acesso mais proximo do destino )
              if (Object.keys(exclusiveAccessEscalator).length !== 0) {
                // Se o spaceId do acesso do elemento da array for igual ao spaceId do acesso da escada de destino
                if (
                  access.spaceId ===
                  exclusiveAccessEscalator.access.userData.spaceId
                ) {
                  this.exclusiveAccess.escalatorLevel = object.level; // Salvo o andar do acesso da origem para escada exclusiva
                  this.exclusiveAccess.escalator = access; // Salvo o a escada exclusiva a partir do ponto de origem
                }
                // Se o spaceId do acesso do elemento da array for diferente do acesso da escada de destino
                else {
                  // Se não possuir nenhum valor já salvo na variavel
                  if (!this.exclusiveAccess.escalator) {
                    // Dessa maneira não vai existir nenhuma opção de escada da origem para o destino

                    this.exclusiveAccess.escalator = null; // Defino a escada exclusiva como nula
                    this.exclusiveAccess.escalatorLevel = null; // Defino o andar da escada exclusiva como nula
                  }
                }
              }
              // Se o objeto não possuir nenhum parametro
              else {
                // Dessa maneira não vai existir nenhuma opção de escada da origem para o destino

                this.exclusiveAccess.escalator = null; // Defino a escada exclusiva como nula
                this.exclusiveAccess.escalatorLevel = null; // Defino o andar da escada exclusiva como nula
              }

              // Se o spaceId do acesso do elemento da array for igual ao spaceId do acesso do elevador de destino
              if (Object.keys(exclusiveAccessElevator).length !== 0) {
                // Se o spaceId do acesso do elemento da array for igual ao spaceId do acesso do elevador de destino
                if (
                  access.spaceId ===
                  exclusiveAccessElevator.access.userData.spaceId
                ) {
                  this.exclusiveAccess.elevatorLevel = object.level; // Salvo o andar do acesso da origem para elevador exclusivo
                  this.exclusiveAccess.elevator = access; // Salvo o elevador exclusivo a partir do ponto de origem
                }
                // Se o spaceId do acesso do elemento da array for diferente do acesso do elevador de destino
                else {
                  object.floorObjects.find((space) => {
                    if (
                      space.spaceId ===
                      exclusiveAccessElevator.access.userData.spaceId
                    ) {
                      this.exclusiveAccess.elevatorLevel = object.level;
                      this.exclusiveAccess.elevator = space;
                      return true;
                    }
                  });
                }
              }
              // Se o resultado da função não retornar nenhum valor dentro
              else {
                // Dessa maneira não vai existir nenhuma opção de elevador da origem para o destino

                this.exclusiveAccess.elevator = null; // Defino o elevador exclusiva como nula
                this.exclusiveAccess.elevatorLevel = null; // Defino o andar do elevador exclusivo como nula
              }
            });
          }
          if (
            this.exclusiveAccess.elevatorLevel !== undefined &&
            this.exclusiveAccess.elevatorLevel !== null &&
            this.exclusiveAccess.escalatorLevel !== undefined &&
            this.exclusiveAccess.escalatorLevel !== null
          ) {
            resolve();
            return true;
          }
        });
      });
    },
    reloadExclusiveAccess() {
      this.exclusiveAccess = this.oldExclusiveAccess;
    },

    /**
     *
     *  Funções de alternar status
     *
     *  @function setPictogramStatus Função para tornar visivel ou invisivel os Pictogramas
     *  @function setCameraView Função que vai retornar câmera ativa no momento
     *  @function changeCameraView Função que alterna câmera ativa no momento
     *  @function showStairs Função para mostrar todas as escadas do mapa
     *  @function showElevators Função para mostrar todos os elevadores do mapa
     *  @function accessibilityActive Função que vai definir o acesso ao proximo andar por elevador
     *
     */

    setPictogramStatus(status) {
      this.currentMap.children.forEach((space) => {
        space.children.forEach((obj) => {
          if (obj.userData.typeObj === 1) {
            obj.visible = status;
          }
        });
      });
    },
    setCameraView() {
      if (this.navpath.length > 0 && this.thirdPersonView) {
        return this.focusCam;
      } else {
        return this.mapCamera;
      }
    },
    changeCameraView() {
      this.thirdPersonView = !this.thirdPersonView;
    },
    showStairs() {
      this.showAllStairs = !this.showAllStairs;
    },
    showElevators() {
      this.showAllElevator = !this.showAllElevator;
    },
    accessibilityActive() {
      this.routeUsingElevator = true;
    },

    /**
     *
     * Função utilizadas durante a rota
     *
     *  @function addObjectPathOnScene Adicionar objeto 3D com formato de tubo no mapa
     *  @function moveObj Atualizar posição da câmera por frame e indicar chegada ao destino
     *  @function updatePersonPosition Atualizar a posição do personagem por frame
     *  @function pauseRoute Pausar ou iniciar a movimentação na rota
     *
     */

    addObjectPathOnScene() {
      if (this.navpath) {
        const updatePath = []; // Preciso passar a posição inicial do cubo, para que o mesmo não começe no primeiro vertice
        updatePath.push(this.personToWalk.position);
        updatePath[0].y = 0;
        // Para cada elemento da array estou determinando a distancia do tudo com o chão
        this.navpath.forEach((vector) => {
          vector.y = 0; // Ajusto a altura do caminho em relação ao chão
          updatePath.push(vector);
        });

        // Estou criando um tubo para indicar o caminho
        const path = new THREE.CatmullRomCurve3(updatePath);
        path.curveType = "catmullrom";
        path.tension = 0;
        const tube = new THREE.TubeGeometry(path, 500, 0.5, 10, false);
        const material = new THREE.MeshBasicMaterial({
          color: new THREE.Color(this.$store.state.mallData.webRouteLineColor),
        }); // Cor do caminho
        this.tubeToShowRoute = new THREE.Mesh(tube, material);
        this.tubeToShowRoute.name = "Route_Path";

        this.endStepToMountTubeRoute = 0;
        this.maxStepCount = this.tubeToShowRoute.geometry.index.count;

        if (
          this.$store.state.mallData.webMapShowPersonage ||
          !this.$vuetify.breakpoint.mobile
        ) {
          this.personToWalk.visible = true; // Tornando a "bola" visivel
        } else {
          this.$store.state.personSpeed = 12; // Ajustando velocidade
          this.personToWalk.visible = false; // Tornando a "bola" visivel
        }

        this.mapScene.add(this.tubeToShowRoute); // Adicionando o tubo como rota na cena

        this.$emit("saveNextFloor", this.nextFloor);

        /*
        this.pathfindinghelper.reset();
        this.pathfindinghelper.setPlayerPosition(this.personToWalk.position);
        this.pathfindinghelper.setTargetPosition(updatePath);
        this.pathfindinghelper.setPath(this.navpath);
        this.mapScene.add(this.pathfindinghelper);
        */
      }
    },
    moveObj(delta) {
      if (!this.navpath || this.navpath.length <= 0) return; // Caso não possua um valor retorna função

      // Caso esteja em rota
      if (this.$store.state.inRoute) {
        this.$store.state.toggleCameraHide = false; // Habilito botão para alternar câmera
        this.$store.state.completeAllPath = false; // Desativo como rota concluida
        let targetPosition = this.navpath[0]; // Determino a posição inicial
        let tubeFinish = false;

        // Copio a posição inicial e somo com a posição do personagem;
        const distance = targetPosition.clone().sub(this.personToWalk.position);
        distance.y += 0.5; // Adiciono uma distancia do personagem em relação ao chão

        if (this.endStepToMountTubeRoute < this.maxStepCount) {
          this.endStepToMountTubeRoute += 300 / this.navpath.length;
          this.tubeToShowRoute.geometry.setDrawRange(
            0,
            this.endStepToMountTubeRoute
          );
        } else {
          if (
            !this.$store.state.mallData.webMapShowPersonage &&
            this.$vuetify.breakpoint.mobile
          ) {
            tubeFinish = true;
          }
        }

        if (
          this.$store.state.mallData.webMapShowPersonage ||
          !this.$vuetify.breakpoint.mobile
        ) {
          this.animationPerson.update(
            delta * (this.$store.state.personSpeed / 3)
          ); // Atualização de frame da animação do personagem andando
        }

        if (!tubeFinish) {
          // Calculo para determinar a posição atual caso seja > que o anterior
          if (distance.lengthSq() > 0.5 * 0.5) {
            distance.normalize();

            this.updatePersonPosition(distance, delta);
          }
          // Caso o calculo para determianr a posição atual seja = ou menos que o anterior
          else {
            this.navpath.shift(); // Remove o primeiro elemento da array

            // Se não tiver mais nenhum elemento na array
            if (this.navpath.length === 0) {
              // Removo o objeto 3D que representa o caminho
              this.mapScene.remove(this.tubeToShowRoute);

              /*
                Caso o acesso exclusivo seja diferente de null
                Preciso deixar ela validação pois caso a rota esteja no mesmo andar porem necessite de um acesso exclusivo
                preciso que ele troque de andar primeiro para que não fique travado no andar de origem
              */
              if (this.exclusiveAccess !== null) {
                this.$emit("waitDialog", true); // Envio a mensagem para carregar o proximo andar
              }
              // Caso o acesso exclusivo seja igual a null
              else {
                // Se o andar atual for igual ao andar de destino significa que a rota foi concluida
                if (
                  this.$store.state.currentFloor.level ===
                  this.$store.state.destinationSpace.floor
                ) {
                  this.$emit("routeFinish");
                } else {
                  this.$emit("waitDialog", true);
                }
              }
            }
          }
        } else {
          this.navpath = [];
          // Removo o objeto 3D que representa o caminho
          this.mapScene.remove(this.tubeToShowRoute);

          /*
            Caso o acesso exclusivo seja diferente de null
            Preciso deixar ela validação pois caso a rota esteja no mesmo andar porem necessite de um acesso exclusivo
            preciso que ele troque de andar primeiro para que não fique travado no andar de origem
          */
          if (this.exclusiveAccess !== null) {
            this.$emit("waitDialog", true); // Envio a mensagem para carregar o proximo andar
          }
          // Caso o acesso exclusivo seja igual a null
          else {
            // Se o andar atual for igual ao andar de destino significa que a rota foi concluida
            if (
              this.$store.state.currentFloor.level ===
              this.$store.state.destinationSpace.floor
            ) {
              this.$emit("routeFinish");
            } else {
              this.$emit("waitDialog", true);
            }
          }
        }
      }
    },
    updatePersonPosition(distance, delta) {
      // Adiciono a nova posição em relação a posição atual
      this.personToWalk.position.add(
        distance.multiplyScalar(delta * this.$store.state.personSpeed)
      );

      // Determino a rotação atual do proximo vertice
      const PersonrotationMatrix = new THREE.Matrix4();
      const Pathquaternion = new THREE.Quaternion();

      // Aponto a rotação em direção ao proximo vertice
      PersonrotationMatrix.lookAt(
        distance,
        new THREE.Vector3(),
        new THREE.Vector3()
      );

      // Salvo a rotação encontrada
      Pathquaternion.setFromRotationMatrix(PersonrotationMatrix);

      // Rotaciono o personagem de acordo com o resultado encontrado
      this.personToWalk.quaternion.rotateTowards(
        Pathquaternion,
        delta * this.$store.state.personSpeed
      );

      const currentPosition = new THREE.Vector3(); // Posição atual da câmera
      const currentLookAt = new THREE.Vector3(); // Posição de foco atual da câmera em relação ao personagem

      // Ajusto uma distancia ideal em relação da câmera com o personagem
      const idealOffset = new THREE.Vector3(0, 15, -30);
      idealOffset.applyQuaternion(this.personToWalk.quaternion); // Aplico a rotação da câmera igual ao do personagem
      idealOffset.add(this.personToWalk.position); // Pego a posição atual do personagem e adiciono a distancia ideal

      // Ajusto uma distancia de foco em relação da câmera com o personagem
      const idealLookAt = new THREE.Vector3(0, 1, 10);
      idealLookAt.applyQuaternion(this.personToWalk.quaternion); // Aplico a rotação do foco igual ao do personagem
      idealLookAt.add(this.personToWalk.position); // Pego a posição atual do personagem e adiciono ao foco ideal

      currentPosition.copy(idealOffset); // Copio os valores encontrados
      currentLookAt.copy(idealLookAt); // Copio os valores encontrados

      this.focusCam.position.copy(currentPosition); // Aplico a posição atual da câmera em relação ao personagem
      this.focusCam.lookAt(currentLookAt); // Aplico o foco atual da câmera em relação ao personagem
      this.focusCam.updateProjectionMatrix();
    },
    pauseRoute(action) {
      if (
        this.$store.state.mallData.webMapShowPersonage ||
        !this.$vuetify.breakpoint.mobile
      ) {
        if (action) {
          this.clock.start();
        } else {
          this.clock.stop();
        }
      } else {
        this.clock.start();
      }
    },

    /**
     *
     * Função de limpeza de dados
     *
     *  @function cleanMap Função para retirar todos os objetos + materiais + texturas da cena
     *  @function resetAllRouteValues Função para tornar os valores padrões para proxima rota
     */

    async cleanMap() {
      new Promise((result) => {
        this.currentMap.children.forEach((space) => {
          if (space.children.length > 0) {
            if (space.children[0].type === "Mesh") {
              space.children[0].material.dispose();
              space.children[0].geometry.dispose();
            } else {
              if (space.children[0].children[0].material.length > 1) {
                space.children[0].children[0].material.forEach((mat) =>
                  mat.dispose()
                );
              } else {
                space.children[0].children[0].material.dispose();
              }
              space.children[0].children[0].geometry.dispose();
            }
          }
        });
        this.mapScene.remove(this.currentMap); // Removo o mapa da cena
        //const navmesh = this.mapScene.getObjectByName("Malha"); // Procuro a malha se existir na cena
        //this.mapScene.remove(navmesh); // Removo a malha se existir na cena
        this.navmesh = null; // Defino a navmesh como nula para adicionar uma nova
        this.ZONE = null; // Defino a ZONA como nula para criar uma nova
        this.groupID = null; // Defino o grupo de ID como nulo para criar um novo
        result();
      });
    },
    resetAllRouteValues() {
      this.setPictogramStatus(true); // Ativando a visualização dos pictogramas no mapa
      this.$emit("applyOriginSpaceName", null); // Então enviado nome da loja selecionada para a lista
      this.$emit("applyDestinationSpaceName", null); // Então enviado nome da loja selecionada para a lista
      this.$emit("changeCameraButton", false); // Alternando status do botão para terceira pessoa
      this.$store.state.originSpace = null; // Removo o ponto de origem fixo
      this.$store.state.destinationSpace = null; // Removo o ponto de destino fixo
      this.personToWalk.visible = false; // Deixo o personagem invisivel
      this.$store.state.completeAllPath = true; // Habilito como rota concluida
      this.$store.state.toggleCameraHide = true; // Desabilito alterar câmera
      this.exclusiveAccess = null; // Removo o acesso exclusivo
      this.$store.state.inRoute = false; // Desabilito para que não esteja mais em rota
      this.thirdPersonView = false; // Desabilito visão em terceira pessoa
      this.routeUsingElevator = false; // Volto para rota com escada
      this.$store.state.personSpeed = 3; // Voltando velocidade inicial do personagem
      this.mapScene.remove(this.tubeToShowRoute); // Removendo o objeto em formado de tubo
      this.originStairsPath = [];
      this.destinationStairsPath = [];

      // Adiciono eventos de acordo com o dispositivo
      this.addEventsListeners();
    },

    /**
     *
     * Funções para identificar conexões de andares e encontrar melhor caminho
     *
     *  @function mountFloorsConnections Função que vai montar a array com todos os andares agrupado por conexão
     *  @function checkFloorConnections Função que vai validar se andar de origem e destino possuem conexão
     *  @function calculateStairsPath Função que vai armazenar a distancia de cada escada para o ponto de origem e destino
     *  @function closestPathBetweenOriginDestination Função que vai retonar a melhor escada a ser utilizada
     *
     */

    mountFloorsConnections() {
      for (
        let floor = 0;
        floor < this.$store.state.mallData.floors.length;
        floor++
      ) {
        // Caso não possua nenhum valor dentro da array
        if (this.floorsConnections.length === 0) {
          // Adiciono na array o andar que ele esta verificando atualmente
          this.floorsConnections.push([
            {
              level: this.$store.state.mallData.floors[floor].level,
            },
          ]);
        }
        // Caso já possua um valor na array
        else {
          // Verifico se o index atual + 1 for menor ou igual ao número de andares continuo o processamento
          if (floor + 1 <= this.$store.state.mallData.floors.length) {
            // Salvo todos os objetos do tipo 3 que existem no andar anterior
            const previousFloor = this.$store.state.mallData.floors[
              floor - 1
            ].floorObjects.filter((type) => type.space.spaceType === 3);

            // Salvo todos os objetos do tipo 3 que existem no andar atual
            const nextFloor = this.$store.state.mallData.floors[
              floor
            ].floorObjects.filter((type) => type.space.spaceType === 3);

            // Função para comparar se pelo menos 1 escada do andar atual existe no andar anterior
            const checkConneciton = (element) =>
              previousFloor.find(
                (escalator) => escalator.spaceId === element.spaceId
              );

            // Salvo o resultado da comparação
            const setConnections = nextFloor.some(checkConneciton);

            // Caso o resultado seja true, os andares possuem uma conexão com escadas
            if (setConnections) {
              // Procuro na array das escadas, o index com o mesmo level do andar anterior
              const findArray = this.floorsConnections.findIndex((array) => {
                return array.find((currentFloor) => {
                  return (
                    currentFloor.level ===
                    this.$store.state.mallData.floors[floor - 1].level
                  );
                });
              });
              // Adiciono o andar que possui uma conexão na mesma array do andar que se interligam
              this.floorsConnections[findArray].push({
                level: this.$store.state.mallData.floors[floor].level,
              });
            }
            // Caso o resultado seja false, o andar atual não tem conexão com o andar anterior, será criada uma nova array
            else {
              this.floorsConnections.push([
                {
                  level: this.$store.state.mallData.floors[floor].level,
                },
              ]);
            }
          }
        }
      }
    },
    checkFloorConnections(array, origin, destination) {
      return (
        array.some(
          (floor) => JSON.stringify(floor) === JSON.stringify(origin)
        ) &&
        array.some(
          (floor) => JSON.stringify(floor) === JSON.stringify(destination)
        )
      );
    },
    calculateStairsPath(startPoint, arrayToSave) {
      const allStairs = this.$store.state.currentFloor.floorObjects.filter(
        (escalator) => escalator.space.spaceType === 3
      );

      for (const stair of allStairs) {
        const access = this.currentMap.getObjectByName(stair.objectName);
        const midPoint = this.findMidPointOfSpace(access);
        const path = this.findPath(startPoint, midPoint, startPoint);

        if (path.length !== undefined) {
          if (arrayToSave === "origin") {
            this.originStairsPath.push({
              spaceId: stair.spaceId,
              objectName: stair.objectName,
              length: path.length,
            });
          } else {
            this.destinationStairsPath.push({
              spaceId: stair.spaceId,
              objectName: stair.objectName,
              length: path.length,
            });
          }
        }
      }
    },
    closestPathBetweenOriginDestination() {
      let lastValue = null;
      let rightAccessToUse = null;

      for (const step of this.filterOriginStairArray) {
        const destination = this.filterDestinationStairsArray.find(
          (stair) => stair.spaceId === step.spaceId
        );

        if (!lastValue) {
          lastValue = step.length + destination.length;
          rightAccessToUse = step;
        } else {
          if (step.length + destination.length < lastValue) {
            lastValue = step.length + destination.length;
            rightAccessToUse = step;
          }
        }
      }

      return rightAccessToUse;
    },

    // Renderizador
    render() {
      // Caso seja versão mobile recorto a tela para que seja ajustada de acordo com o tamanho do container
      if (this.$vuetify.breakpoint.mobile) {
        this.renderer.setScissorTest(false);
        this.renderer.clear();

        this.renderer.setScissorTest(true);

        const element = this.mapScene.userData.element;
        const rect = element.getBoundingClientRect();

        if (
          rect.bottom < 0 ||
          rect.top > this.renderer.domElement.clientHeight ||
          rect.right < 0 ||
          rect.left > this.renderer.domElement.clientWidth
        ) {
          return;
        }

        const width = rect.right - rect.left;
        const height = rect.bottom - rect.top;
        const left = rect.left;
        const bottom = this.renderer.domElement.clientHeight - rect.bottom;

        this.renderer.setViewport(left, bottom, width, height);
        this.renderer.setScissor(left, bottom, width, height);
      }

      this.control.update(); // Atualizo o controle da câmera
      this.mapCamera.updateMatrixWorld(); // Atualizo a posição da câmera
      this.focusCam.updateMatrixWorld(); // Atualizo a posição da câmera

      // Caso não esteja em rota
      if (!this.$store.state.inRoute) {
        // Caso seja versão desktop utilizo configurações de raycaster de desktop
        if (!this.$vuetify.breakpoint.mobile) {
          this.raycaster.layers.set(1); // Determino qual layer com espaços podem ser selecionadas
          // Caso o valor do mouse não seja null
          if (this.mouse !== null) {
            this.raycaster.setFromCamera(this.mouse, this.setCameraView());
            const found = this.raycaster.intersectObjects(
              this.mapScene.children,
              true
            );

            this.desktopSelection(found); // Função para determinar qual espaço foi selecionado
          }
        }
      }
      this.moveObj(Math.min(this.clock.getDelta(), 0.1));
      this.renderer.render(this.mapScene, this.setCameraView());
    },
    // Animação THREE JS
    animate() {
      requestAnimationFrame(this.animate);
      this.render();
    },

    // Função para mostrar navmesh apenas para testes
    showNavMesh(navmesh) {
      //navmesh.children[0].material.wireframe = true;
      navmesh.children[0].material.color.g = 0;
      //navmesh.children[0].position.z = 1;
      this.mapScene.add(navmesh);
    },
  },
  watch: {
    // Monitorando a variavel de mostrar as escadas
    showAllStairs() {
      // Caso seja true
      if (this.showAllStairs) {
        // Procuro todos os objetos 3D do mapa atual com o atributo escalator
        this.currentMap.children.forEach((object) => {
          // Caso possua o atributo
          if (object.userData.escalator) {
            object.currentHex = object.material.color.getHex(); // Salvo a cor antiga
            object.material.color = new THREE.Color(
              this.$store.state.currentFloor.stairsSpaceColorWeb
            ); // Aplico cor nova
          }
        });
      }
      // Caso seja falso
      else {
        // Procuro todos os objetos 3D do mapa atual com o atributo escalator
        this.currentMap.children.forEach((object) => {
          if (object.userData.escalator) {
            object.material.color = object.default; // Aplico cor antiga
          }
        });
      }
    },
    // Monitorando a variavel de mostrar os elevadores
    showAllElevator() {
      // Caso seja true
      if (this.showAllElevator) {
        // Procuro todos os objetos 3D do mapa atual com o atributo elevator
        this.currentMap.children.forEach((object) => {
          if (object.userData.elevator) {
            object.currentHex = object.material.color.getHex(); // Salvo a cor antiga
            object.material.color = new THREE.Color(
              this.$store.state.currentFloor.elevatorsSpaceColorWeb
            ); // Aplico cor nova
          }
        });
      }
      // Caso seja falso
      else {
        // Procuro todos os objetos 3D do mapa atual com o atributo elevator
        this.currentMap.children.forEach((object) => {
          // Caso possua o atributo
          if (object.userData.elevator) {
            object.material.color = object.default; // Aplico cor antiga
          }
        });
      }
    },
    // Monitorando o espaço no qual o mouse passou por cima
    selectedSpace() {
      // Caso possua algum valor
      if (this.selectedSpace) {
        if (!this.$vuetify.breakpoint.mobile) {
          this.canvasContainer.addEventListener("click", this.onSelect); // Monitoramento do clique em lojas para executar função
        }
        // Procuro no mapa atual o objeto 3D selecionado
        this.$store.state.currentFloor.floorObjects.find((object) => {
          if (object.objectName === this.selectedSpace.name) {
            this.selectedSpaceName = object.space.name;
          }
        });
        this.$emit("updateSpaceName", this.selectedSpaceName); // Envio o nome do espaço para aparecer na tela
      }
      // Caso não possua algum valor
      else {
        this.selectedSpaceName = null; // Apago o nome da loja selecionada
        this.$emit("updateSpaceName", this.selectedSpaceName); // Envio o nome como null
        if (!this.$vuetify.breakpoint.mobile) {
          this.canvasContainer.removeEventListener("click", this.onSelect); // Removo o monitoramento por click
        }
      }
    },
    // Monitorando o espaço de origem
    originSpace() {
      // Caso possua um valor
      if (this.originSpace !== null) {
        this.originSpace.currentHex = this.originSpace.material.color.getHex(); // Salvo cor default
        // Aplico nova cor de acordo com o que recebo do BD
        this.originSpace.material.color = new THREE.Color(
          this.$store.state.currentFloor.sourceSpaceColorWeb
        );
        this.originSpace.translateZ(0.2); // Movo o objeto 3D para cima

        // Salvo o centro do objeto 3D de origem
        const centerFOcus = new THREE.Box3()
          .setFromObject(this.originSpace)
          .getCenter(new THREE.Vector3());
        //this.mapCamera.position.set(centerFOcus.x, 100, centerFOcus.z); // Posiciono a câmera acima da loja
        this.mapCamera.position.y = 200;
        this.mapCamera.lookAt(centerFOcus); // Câmera vai filmar o centro do objeto 3D de origem
        this.mapCamera.target = centerFOcus; // Câmera vai ter como alvo o centro do objeto 3D de origem
        //this.control.target = centerFOcus; // Câmera Orbital vai ter como alvo o centro do objeto 3D de origem
      } else {
        //this.control.reset(); // Volto a câmera principal na posição inicial
      }
    },
    // Monitorando o espaço de destino
    destinationSpace() {
      // Caso possua um valor
      if (this.destinationSpace) {
        this.destinationSpace.currentHex =
          this.destinationSpace.material.color.getHex(); // Salvo cor default
        this.destinationSpace.material.color = new THREE.Color(
          this.$store.state.currentFloor.targetSpaceColorWeb
        );
        // Aplico nova cor
        this.destinationSpace.translateZ(0.2); // Movo o objeto 3D para cima

        // Salvo o centro do objeto 3D de origem
        const centerFOcus = new THREE.Box3()
          .setFromObject(this.destinationSpace)
          .getCenter(new THREE.Vector3());
        //this.mapCamera.position.set(centerFOcus.x, 100, centerFOcus.z); // Posiciono a câmera acima da loja
        //this.mapCamera.position.y = 200;
        this.mapCamera.lookAt(centerFOcus); // Câmera vai filmar o centro do objeto 3D de origem
        this.mapCamera.target = centerFOcus; // Câmera vai ter como alvo o centro do objeto 3D de origem
        //this.control.target = centerFOcus; // Câmera Orbital vai ter como alvo o centro do objeto 3D de origem
      } else {
        //this.control.reset(); // Volto a câmera principal na posição inicial
      }
    },
    // Monitorando a variavel para trocar o mapa, toda vez que o andar por trocado
    "$store.state.currentFloor": {
      handler() {
        setTimeout(() => {
          this.loadMap(); // Função para carregar os mapas
        }, 500); // Função para carregar mapa
      },
      deep: true,
    },
    // Monitorando a variavel de % de download do mapa
    "$store.state.percentLoaded": {
      handler() {
        // Caso tenha concluido 100% o download do mapa
        if (this.$store.state.percentLoaded === 100) {
          if (!this.$store.state.inRoute) {
            this.$store.state.completeAllPath = true; // Alterando o estado da variavel para usuario poder utilizar os botões
            this.control.reset(); // Volto posição inicial da câmera
          }
        }
      },
      deep: true,
    },
    // Monitorando a variavel para definir origem fixa
    "$store.state.originSpace": {
      handler() {
        // Caso possua algum valor atribuido
        if (this.$store.state.originSpace !== null) {
          // Caso o andar da loja de origem seja igual ao andar atual
          if (
            this.$store.state.originSpace.floor ===
            this.$store.state.currentFloor.level
          ) {
            // Caso possua algum valor de origem temporaria ativa
            if (this.originSpace !== null) {
              this.originSpace.material.color.setHex(
                this.originSpace.currentHex
              ); // Aplico a cor default
              this.originSpace.translateZ(-0.2); // Baixo o elemento 3D
              this.originSpace = this.currentMap.getObjectByName(
                this.$store.state.originSpace.objectName
              ); // Procuro no mapa atual a loja como  mesmo nome de objeto da loja fixa e salvo como origem temporaria
            }
            // Caso não tenha uma origem temporaria ativa
            else {
              this.originSpace = this.currentMap.getObjectByName(
                this.$store.state.originSpace.objectName
              ); // Procuro no mapa atual a loja como  mesmo nome de objeto da loja fixa e salvo como origem temporaria
            }
          }
          // Caso a loja de origem não esteja no mesmo andar atual
          else {
            this.$emit("changeFloor", this.$store.state.originSpace.floor); // Envio comando para trocar o botão do andar
          }
        }
        // Caso o valor de origem fixa seja removido
        else {
          // Se possuir valor de origem temporaria
          if (this.originSpace !== null) {
            this.originSpace.material.color.setHex(this.originSpace.currentHex); // Aplico cor default
            this.originSpace.translateZ(-0.2); // Baixo o elemento 3D
            this.originSpace = null; // Apago o valor atribuido
            this.control.reset(); // Volto a câmera principal na posição inicial
          }
        }
      },
      deep: true,
    },
    // Monitorando a variavel para definir o destino fixo
    "$store.state.destinationSpace": {
      handler() {
        // Caso possua um valor de destino fixo atribuido
        if (this.$store.state.destinationSpace !== null) {
          if (
            // Caso o destino fixo esteja no mesmo andar do andar atual
            this.$store.state.destinationSpace.floor ===
            this.$store.state.currentFloor.level
          ) {
            // Se possuir algum desinto temporario
            if (this.destinationSpace !== null) {
              this.destinationSpace.material.color.setHex(
                this.destinationSpace.currentHex
              ); // Aplico cor default
              this.destinationSpace.translateZ(-0.2); // Baixo elemento 3D
              //this.destinationSpace = null;
              this.destinationSpace = this.currentMap.getObjectByName(
                this.$store.state.destinationSpace.objectName
              ); // Procuro no mapa atual o objeto 3D com mesmo nome do objeto da loja fixa e salvo como destino

              const midPoint = this.findMidPointOfSpace(this.destinationSpace);
              this.calculateStairsPath(midPoint, "destination");
              setTimeout(() => {
                this.setExclusiveAccess();
              }, 100);
            }
            // Caso não tenha um destino temporario
            else {
              this.destinationSpace = this.currentMap.getObjectByName(
                this.$store.state.destinationSpace.objectName
              ); // Procuro no mapa atual o objeto 3D com mesmo nome do objeto da loja fixa e salvo como destino
              const midPoint = this.findMidPointOfSpace(this.destinationSpace);
              this.calculateStairsPath(midPoint, "destination");
              setTimeout(() => {
                this.setExclusiveAccess();
              }, 100);
            }
          }
          // Caso a loja de destino não esteja no mesmo andar atual
          else {
            this.$emit("changeFloor", this.$store.state.destinationSpace.floor); // Envio comando para trocar o botão do andar
          }
        }
        // Caso não possua um destino fixo atrubuido
        else {
          // Se possuir um destino temporario atribuido
          if (this.destinationSpace !== null) {
            this.destinationSpace.material.color.setHex(
              this.destinationSpace.currentHex
            ); // Aplico cor default
            this.destinationSpace.translateZ(-0.2); // Baixo elemento 3D
            this.destinationSpace = null; // Apago o valor da variavel
            this.control.reset(); // Volto a câmera principal na posição inicial
          }
        }
      },
      deep: true,
    },
    "currentMap.visible": {
      handler() {
        if (this.currentMap.visible) {
          // Caso possua algum valor atribuido na origem e destino
          if (this.$store.state.inRoute) {
            setTimeout(() => {
              this.traceRoute(); // Função para procurar uma rota
            }, 500);
          } else {
            this.$store.state.completeAllPath = true; // Alterando o estado da variavel para usuario poder utilizar os botões
            this.control.reset(); // Volto posição inicial da câmera
            // Caso a origem possua algum valor
            if (this.$store.state.originSpace) {
              // Se o andar atual for igual ao andar de origem
              if (
                this.$store.state.originSpace.floor ===
                this.$store.state.currentFloor.level
              ) {
                setTimeout(() => {
                  // Salvo como origem atual o objeto 3D encontrado no mapa atual
                  this.originSpace = this.currentMap.getObjectByName(
                    this.$store.state.originSpace.objectName
                  );
                }, 100);
              }
            }

            // Caso o destino possua algum valor
            if (this.$store.state.destinationSpace) {
              // Se o andar atual for igual ao andar de destino
              if (
                this.$store.state.destinationSpace.floor ===
                this.$store.state.currentFloor.level
              ) {
                setTimeout(() => {
                  this.destinationSpace = this.currentMap.getObjectByName(
                    this.$store.state.destinationSpace.objectName
                  ); // Procuro no mapa atual o objeto 3D com mesmo nome do objeto da loja fixa e salvo como destino
                  const midPoint = this.findMidPointOfSpace(
                    this.destinationSpace
                  );
                  this.calculateStairsPath(midPoint, "destination");
                  this.setExclusiveAccess(); // Procuro o ponto de acesso exclusivo mais proximo do ponto de destino
                }, 100);
              }
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    nextFloor() {
      if (this.routeUsingElevator) {
        return this.$store.state.destinationSpace.floor;
      } else {
        if (
          this.$store.state.currentFloor.level >
          this.$store.state.destinationSpace.floor
        ) {
          return this.$store.state.currentFloor.level - 1;
        } else {
          return this.$store.state.currentFloor.level + 1;
        }
      }
    },
    filterOriginStairArray() {
      return this.originStairsPath.filter((origin) =>
        this.destinationStairsPath.some(
          (destination) => destination.spaceId === origin.spaceId
        )
      );
    },
    filterDestinationStairsArray() {
      return this.destinationStairsPath.filter((destination) =>
        this.originStairsPath.some(
          (origin) => origin.spaceId === destination.spaceId
        )
      );
    },
  },
};
</script>
<style scope>
.mobile {
  position: absolute;
  top: 0px;
  z-index: 0;
}

.map {
  margin: 0px;
  padding: 0px;
  height: 100%;
}
</style>
