import Vue from "vue";
import VueRouter from "vue-router";
import MallViewer from "@/views/MallViewer.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/mall/:code/*",
    name: "MapViewer",
    component: MallViewer,
    beforeEnter: (to, from, next) => {
      const path = to.params.pathMatch.split("/");
      let box = path[0];
      to.params.box =
        box !== "true" && box !== "false"
          ? false
          : box === "true" || !(box === "false");

      to.params.destino =
        path.length === 3
          ? path[2] !== ""
            ? path[2]
            : undefined
          : path.length === 2
          ? path[1] !== "" && box !== "true" && box !== "false"
            ? path[1]
            : undefined
          : undefined;

      to.params.origin =
        path.length === 3
          ? path[1] !== ""
            ? path[1]
            : undefined
          : path.length === 2
          ? box !== ""
            ? path[1]
            : undefined
          : box !== "true" && box !== "false" && box !== ""
          ? box
          : undefined;

      // Atualiza a URL para incluir o parâmetro :box
      next();
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
