import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    codeMall: null, // Codigo do shopping informado
    mallData: null, // Todas as informações do shopping (andares, lojas, logos e etc...)
    currentFloor: null, // Andar atual com todos os objetos e etc...
    originSpace: null, // Origem selecionado pelo usuario
    destinationSpace: null, // Destino final selecionado pelo usuario
    percentLoaded: 0, // Percentual carregado
    completeAllPath: false, // Controlador dos botões em relação ao caminho
    inRoute: false, // Controlador para indicar que ainda esta em uma rota
    toggleCameraHide: true, // Controlador para esconder botão para alternar camera
    personSpeed: 3, // Velocidade do personagem
  },
  getters: {
    getFloorLevelsDiference(state) {
      const diferenceValue = Math.abs(
        state.currentFloor.level - state.destinationSpace.floor
      );
      if (diferenceValue > 0) {
        return true;
      } else {
        return false;
      }
    },
    getCurrentFloorStairs(state) {
      return state.currentFloor.floorObjects.filter(
        (escalator) => escalator.space.spaceType === 3
      );
    },
    getHighFloorStairs(state, getters) {
      return state.mallData.floors
        .filter((floor) => floor.level === state.currentFloor.level + 1)[0]
        .floorObjects.filter((escalator) =>
          getters.getCurrentFloorStairs.some(
            (stairs) => stairs.spaceId === escalator.spaceId
          )
        );
    },
    getLowerFloorStairs(state, getters) {
      return state.mallData.floors
        .filter((floor) => floor.level === state.currentFloor.level - 1)[0]
        .floorObjects.filter((escalator) =>
          getters.getCurrentFloorStairs.some(
            (stairs) => stairs.spaceId === escalator.spaceId
          )
        );
    },
  },
  mutations: {
    setOrigin(state, origin) {
      if (origin) {
        state.mallData.floors.forEach((floor) => {
          floor.floorObjects.find((object) => {
            if (object.objectName === origin.objectName) {
              state.originSpace = {
                floor: floor.level,
                objectName: object.objectName,
                spaceName: origin.space.name,
                spaceId: origin.spaceId,
              };
            }
          });
        });
      }
    },
    setDestination(state, destination) {
      if (destination) {
        state.mallData.floors.forEach((floor) => {
          floor.floorObjects.find((object) => {
            if (object.objectName === destination.objectName) {
              state.destinationSpace = {
                floor: floor.level,
                objectName: object.objectName,
                spaceName: destination.space.name,
                spaceId: destination.spaceId,
              };
            }
          });
        });
      }
    },
  },
  actions: {},
  modules: {},
});
